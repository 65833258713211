import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NoDecorationLink } from '../components/styled';
import { toast } from 'react-toastify';
import * as moment from 'moment';

import { useStateValue } from '../state';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { hexToRgb } from '../utils/hexToRgb';
import { TrashIcon, DisabledIcon, UndoIcon } from './icons';
import {
  Button,
  MaterialInput,
  MaterialSelect,
  MaterialInputLabel,
  MaterialMenuItem,
  MaterialDatePicker,
  ConfirmModal,
  MoneyMask,
} from './';
import FormControl from '@material-ui/core/FormControl';
import Arabesco from '../images/arabescos.svg';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import { bride } from '../services/api';
//import { /*investmentValue*/ invitedNumber } from '../utils/dropdownValues';
import dateUndefined from '../utils/undefinedDate';
import Grid from '@material-ui/core/Grid';

const userTypeOptions = [
  { id: 2, name: 'Convidado' },
  { id: 3, name: 'Assessor' },
];

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5em;

  background-color: ${theme.colors.white};
  border-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const EventHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  @media ${medias.phoneMax} {
    padding-right: 0.25em;
    font-size: 0.8em;
  }
`;

const AssetContainer = styled.div`
  @media ${medias.tabletMin} {
    background-size: contain;
  }

  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  border-radius: 10px;
  ${({ bottom }) => bottom && `margin-top: 1em;`}
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
`;

const ItemContainer = styled(RowContainer)`
  justify-content: flex-start;
  border-bottom: 2px solid ${theme.colors.grey1};
`;

const UserContainer = styled(RowContainer)`
  margin: 5px;
  ${({ border }) =>
    border &&
    `
      border-bottom: 2px solid ${theme.colors.grey1};
      padding-bottom: 10px;
    `};
  ${({ bride }) =>
    !bride && 'width: 50%; align-self: flex-start; margin: 5px 5px 5px 5%'}
`;

const RegionContainer = styled(RowContainer)`
  flex-grow: 1;
  flex-wrap: wrap;
`;

const HistoryRowContainer = styled(RowContainer)`
  justify-content: space-evenly;
  ${({ border }) => border && `border-bottom: 2px solid ${theme.colors.grey1};`}
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
`;

const BalanceContainer = styled.div`
  width: 100%;
  height: 65px;
  background-color: ${theme.colors.text_heading};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
`;

const EventTitle = styled.h4`
  color: ${theme.colors.text_heading};
  ${({ date }) => date && `margin-left: 2em;`}
`;

const Text = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.9em;
  }

  font-size: ${theme.fontSize}px;
  color: ${theme.colors.grey3};
  margin: 0;
  ${({ region }) => region && `width: 30%;`}
`;

const BalanceText = styled.p`
  font-size: ${theme.fontSizeMed}px;
  color: ${theme.colors.white};
  margin: 0 5px 0 0;
`;

const HistoryTitle = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.6em;
  }

  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
  margin: 10px 0 3px 0;
`;

const HistoryText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.9em;
  }

  font-size: ${theme.fontSizeMed}px;
  color: ${theme.colors.grey3};
  margin: 0 0 10px 5px;
`;

const Title = styled.h2`
  color: ${theme.colors.grey3};
`;

const FormTitle = styled.h4`
  color: ${theme.colors.text_heading};
`;

const PointsText = styled(BalanceText)`
  ${({ expired }) => expired && `text-decoration: line-through;`}
  font-weight: bold;
  color: ${theme.colors.primary};
  margin: 0 0 0 0;
`;

const StyledButton = styled(Button)`
  width: ${({ intern }) => (intern ? '100%' : '90%')};
  background-color: ${({ opened }) =>
    opened ? theme.colors.white : theme.colors.text_heading};
  border: 2px solid ${theme.colors.text_heading};
  color: ${({ opened }) =>
    opened ? theme.colors.text_heading : theme.colors.white};
  margin-top: 1.5em;
`;

const StyledFormControl = styled(props => <FormControl {...props} />)`
  width: 90%;
`;

const StyledNoDecorationLink = styled(props => <NoDecorationLink {...props} />)`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const StyledTrashIcon = styled(TrashIcon)`
  cursor: pointer;
`;

const SegmentContainer = styled.div`
  @media ${medias.phoneMax} {
    width: 50%;
  }

  display: flex;
  width: 30%;
  align-items: center;
  justify-content: flex-start;
`;

const StyledIcon = styled.img`
  width: 30px;
  height: 30px;
  fill: ${({ color }) => color};
`;

const ItemText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.65em;
    width: 40%;
  }

  display: flex;
  align-self: center;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.text_heading};
  margin: 0 0 0 0.5em;
  word-wrap: break-word;
`;

const AttentionText = styled.p`
  font-size: 14px;
  color: red;
  align-self: flex-start;
  text-align: left;
  margin: 0;
`;

const StyledLink = styled.a`
  color: inherit;
`;

const StyledDatePicker = styled(props => <MaterialDatePicker {...props} />)`
  width: 45%;
`;

const DisabledContainer = styled.span`
  display: flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
  @media ${medias.phoneMax} {
    padding-left: 0.25em;
    font-size: 0.6em;
    font-weight: bold;
  }

  svg {
    margin-left: 5px;
  }
`;

const EventsCard = ({ data: event, updatePage, renderOpen, selectedField }) => {
  const [opened, setOpened] = useState(false);
  const [userForm, setUserForm] = useState({
    name: '',
    email: '',
  });
  const [regionSelect, setRegionSelect] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [eventRegions, setEventRegions] = useState(null);
  const [users, setUsers] = useState(null);
  const [avaliableRegions, setAvaliableRegions] = useState(null);
  const [pointHistory, setPointHistory] = useState(null);
  const [closedSegments, setClosedSegments] = useState(null);
  const [userType, setUserType] = useState(0);
  const [eventName, setEventName] = useState(event.evento.nomeEvento);
  const [invited, setInvited] = useState(event.evento.convidados || 0);
  const [investment, setInvestment] = useState(event.evento.investimento || 0);
  const [ceremonyLocation, setCeremonyLocation] = useState(
    event.evento.localCerimonia
  );
  const [receptionLocation, setReceptionLocation] = useState(
    event.evento.localRecepcao
  );
  const [weddingDate, setWeddingDate] = useState(
    event.evento.data || new Date()
  );
  const [modalConfirmRegion, setModalConfirmRegion] = useState(false);
  const [
    {
      session: { selectedRegion },
    },
  ] = useStateValue();

  const pointsHistory = useRef(null);
  const eventDate = useRef(null);
  const isBuyRegionClickAvaliable =
    avaliableRegions && avaliableRegions.length && regionSelect !== 0;
  const isAddUsersClickAvaliable =
    userForm.name && userForm.email && userType !== 0;
  const isChangeEventClickAvaliable = investment !== 0 && invited !== 0;

  useEffect(() => {
    if (renderOpen && !opened) {
      setOpened(true);
    }

    if (renderOpen && opened) {
      selectedField === 'date'
        ? eventDate.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        : pointsHistory.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
    }
  }, [renderOpen, opened, selectedField]);

  useFetch({
    url: urls.bridesContent.eventRegions(event.evento.codEvento),
    method: method.GET,
    condition: () => !eventRegions,
    callback: data => setEventRegions(data),
  });

  useFetch({
    url: urls.bridesContent.usersFromEvent(event.evento.codEvento),
    method: method.GET,
    condition: () => !users,
    callback: data => setUsers(data),
  });

  useFetch({
    url: urls.bridesContent.eventAvaliableRegions(event.evento.codEvento),
    method: method.GET,
    condition: () => !avaliableRegions,
    callback: data => setAvaliableRegions(data),
  });

  useFetch({
    url: urls.bridesContent.getPointsHistory(
      event.evento.codEvento,
      selectedRegion
    ),
    method: method.GET,
    condition: () => selectedRegion && !pointHistory,
    callback: data => setPointHistory(data),
  });

  useFetch({
    url: urls.bridesContent.getClosedSegments(event.evento.codEvento),
    method: method.GET,
    condition: () => !closedSegments,
    callback: data => setClosedSegments(data),
  });

  const personAdded = person => {
    toast.success('Usuário adicionado com sucesso!');
    const newUsers = [...users, person];
    setUsers(newUsers);
  };

  const addPerson = async () => {
    if (!userForm.name || !userForm.email) {
      toast.error('Preencha nome e email para adicionar usuario');
      return;
    }
    const payload = {
      name: userForm.name,
      email: userForm.email,
      codEvent: event.evento.codEvento,
      userType,
    };
    const response = await bride.addUserToEvent(payload);
    setUserForm({
      name: '',
      email: '',
    });
    response.ok
      ? personAdded(response.data)
      : response.status === 412
      ? toast.error(response.data)
      : toast.error('Não foi possivel adicionar usuario!');
  };

  const userRemoved = cod => {
    const newUsers = users.filter(({ codUserEvent }) => codUserEvent !== cod);
    setUsers(newUsers);
    toast.success('Usuário removido com sucesso.');
  };

  const removeUser = async id => {
    const response = await bride.deleteUserFromEvent(id);

    if (response.status === 406) {
      toast.error(
        'Erro ao deletar usuário: O usuário possui vínculos ativos no sistema!'
      );
      return;
    }

    if (response.status === 412) {
      toast.error('Erro ao deletar usuário: Não encontrado no sistema!');
      return;
    }

    if (response.ok && response.data) {
      userRemoved(id);
    } else {
      toast.error('Erro ao deletar usuário!');
    }
  };

  const addRegion = async () => {
    const payload = {
      event: event.evento.codEvento,
      region: regionSelect,
    };

    const response = await bride.buyEventRegion(payload);

    if (!response.ok) {
      if (response.status === 412) {
        toast.error('Saldo insuficiente!');
        return;
      }
      toast.error('Falha ao comprar a Região!');
      return;
    }

    toast.success('Região comprada com sucesso!');
    window.location.reload();
  };

  const handleUserFormChange = (newText, field) => {
    setUserForm({
      ...userForm,
      [field]: newText,
    });
  };

  const handleRegionSelectChange = newRegion => {
    setRegionSelect(newRegion);
  };

  const onClose = () => {
    setModalVisible(false);
  };

  const handleDateActive = () => {
    const now = moment();
    const deadlineDate = now.subtract(30, 'days').format('YYYY-MM-DD');

    if (event.evento.data) {
      return deadlineDate <= event.evento.data;
    }
    return deadlineDate <= weddingDate;
  };

  const changeEvent = async () => {
    const payload = {
      id: event.evento.codEvento,
      eventName,
      weddingDate,
      investment,
      invited,
      ceremonyLocation,
      receptionLocation,
    };

    if (weddingDate && !event.evento.data) {
      toast.info(
        'Você só pode alterar a data uma vez. Caso já tenha alterado e necessite alterar novamente, entre em contato conosco',
        { autoClose: false }
      );
    }

    const response = await bride.updateEvent(payload);

    if (response.ok && response.data) {
      toast.success('Evento atualizado com sucesso!', {
        onClose: () => window.location.reload(),
      });

      return;
    }
    if (response.data.message === 'Already modified.') {
      return toast.error(
        'Erro! A data já alterada. Por favor, entre em contato.'
      );
    }

    toast.error('Erro ao atualizar o evento!');
  };

  const handleDisableEvent = async () => {
    const payload = {
      event: event.evento.codEvento,
    };

    const response = await bride.disableEvent(payload);

    if (!response.ok) {
      const message =
        event.evento.inativo === 0
          ? 'Falha ao desabilitar o evento!'
          : 'Falha ao habilitar o evento!';
      toast.error(message);
      return;
    }

    const message =
      event.evento.inativo === 0
        ? 'Evento desabilitado com sucesso!'
        : 'Evento habilitado com sucesso!';

    toast.success(message);
    updatePage();
  };

  const {
    evento: { nomeEvento, data, localCerimonia, inativo },
    tipoUsuario,
    principal,
  } = event;

  const { name: userName, email: userEmail } = userForm;

  return (
    <Wrapper>
      <AssetContainer />
      <RowContainer>
        <EventHeaderContainer>
          <EventTitle>Evento: {nomeEvento}</EventTitle>
          {data !== dateUndefined ? (
            <EventTitle date>
              {moment(data)
                .utc()
                .format('DD/MM/YYYY')}
            </EventTitle>
          ) : (
            <EventTitle date>Data indefinida</EventTitle>
          )}
        </EventHeaderContainer>
        {tipoUsuario === 'Noivo' && principal === 1 ? (
          inativo === 1 ? (
            <UndoIcon
              style={{ cursor: 'pointer' }}
              onClick={() => handleDisableEvent()}
            />
          ) : (
            <DisabledContainer onClick={() => setModalVisible(true)}>
              DESATIVAR
              <DisabledIcon />
            </DisabledContainer>
          )
        ) : tipoUsuario === 'Convidado' ? (
          <EventTitle>Convidado</EventTitle>
        ) : null}
      </RowContainer>
      <RowContainer>
        {localCerimonia && <Text>Local: {localCerimonia}</Text>}
      </RowContainer>
      {opened && (
        <>
          <Title>Contratado</Title>
          {closedSegments &&
            closedSegments.map(
              ({ id, supplierName, segmentName, segmentIcon }) => (
                <ItemContainer key={id}>
                  <SegmentContainer>
                    <StyledIcon src={segmentIcon} />
                    <ItemText>{segmentName}</ItemText>
                  </SegmentContainer>
                  <Text>{supplierName}</Text>
                </ItemContainer>
              )
            )}
          {tipoUsuario === 'Noivo' && principal === 1 && (
            <>
              <Title ref={eventDate}>Evento</Title>
              <RowContainer>
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Nome do evento"
                  value={eventName}
                  onChange={e => setEventName(e.target.value)}
                />
                <StyledDatePicker
                  label="Data do evento"
                  format={'DD/MM/YYYY'}
                  value={weddingDate === dateUndefined ? null : weddingDate}
                  onChange={date => setWeddingDate(date)}
                  disabled={weddingDate !== dateUndefined}
                />
              </RowContainer>
              <RowContainer>
                <AttentionText>
                  <b>Atenção:</b> Se sua data de evento já está definida e você
                  deseja alterar, por favor, entre em contato com nosso
                  atendimento através do whatsapp{' '}
                  <StyledLink
                    href="https://api.whatsapp.com/send?phone=5531988230342&text=Bom%20dia!%20Quero%20alterar%20minha%20data%20de%20evento%20no%20sistema%20da%20Rede%20Celebrar!%20Meu%20email%20%C3%A9%20"
                    target="_blank"
                  >
                    (31) 98823-0342
                  </StyledLink>{' '}
                  ou email{' '}
                  <StyledLink href="mailto:contato@redecelebrar.com.br">
                    contato@redecelebrar.com.br
                  </StyledLink>
                  .
                </AttentionText>
              </RowContainer>

              <RowContainer>
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Investimento estimado"
                  value={investment}
                  onChange={e => setInvestment(e.target.value)}
                  InputProps={{
                    inputComponent: MoneyMask,
                  }}
                />
                {/* <MaterialInputLabel htmlFor="investment-simple">
                    Investimento estimado
                  </MaterialInputLabel>
                  <MaterialSelect
                    onChange={event => setInvestment(event.target.value)}
                    value={investment}
                    inputProps={{
                      name: 'investment',
                      id: 'investment-simple',
                    }}
                  >
                    <MaterialMenuItem value={0}>Selecione</MaterialMenuItem>
                    {investmentValue.map(item => (
                      <MaterialMenuItem value={item} key={item}>
                        {item}
                      </MaterialMenuItem>
                    ))}
                    </MaterialSelect>
                    </FormControl>*/}
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Convidados"
                  value={invited}
                  type="number"
                  onChange={e => setInvited(e.target.value)}
                />
              </RowContainer>
              <RowContainer>
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Local da cerimônia"
                  value={ceremonyLocation}
                  onChange={e => setCeremonyLocation(e.target.value)}
                />
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Local da recepção"
                  value={receptionLocation}
                  onChange={e => setReceptionLocation(e.target.value)}
                />
              </RowContainer>
              <StyledButton
                text="ALTERAR"
                click={changeEvent}
                disabled={!isChangeEventClickAvaliable}
              />
            </>
          )}
          <Title>Usuários</Title>
          {users &&
            users.map(({ codUserEvent, codPerson, name, email }) => (
              <UserContainer
                key={codPerson}
                border={codPerson === users.length - 1}
                bride={tipoUsuario === 'Noivo'}
              >
                <Grid container spacing={8}>
                  <Grid item xs={5}>
                    <Text>{name}</Text>
                  </Grid>{' '}
                  <Grid item xs={6}>
                    {' '}
                    <Text>{email}</Text>
                  </Grid>
                  {tipoUsuario === 'Noivo' && (
                    <Grid item xs={1}>
                      <StyledTrashIcon
                        onClick={() => removeUser(codUserEvent)}
                      />
                    </Grid>
                  )}
                </Grid>
              </UserContainer>
            ))}
          {tipoUsuario === 'Noivo' && (
            <FormContainer>
              <FormTitle>Adicionar usuários</FormTitle>
              <MaterialInput
                style={{ width: '100%' }}
                label="Nome"
                value={userName}
                onChange={event =>
                  handleUserFormChange(event.target.value, 'name')
                }
              />
              <MaterialInput
                style={{ width: '100%' }}
                label="E-mail"
                value={userEmail}
                onChange={event =>
                  handleUserFormChange(event.target.value, 'email')
                }
              />
              <FormControl style={{ width: '100%' }}>
                <MaterialInputLabel htmlFor="user-simple">
                  Tipo do usuário
                </MaterialInputLabel>
                <MaterialSelect
                  onChange={event => setUserType(event.target.value)}
                  value={userType}
                  inputProps={{
                    name: 'user-type',
                    id: 'user-type-simple',
                  }}
                >
                  <MaterialMenuItem value={0}>Selecione</MaterialMenuItem>
                  {userTypeOptions.map(({ id, name }) => (
                    <MaterialMenuItem key={id} value={id}>
                      {name}
                    </MaterialMenuItem>
                  ))}
                </MaterialSelect>
              </FormControl>
              <StyledButton
                intern
                text="ADICIONAR"
                click={addPerson}
                disabled={!isAddUsersClickAvaliable}
              />
            </FormContainer>
          )}
          <Title>Regiões</Title>
          <RegionContainer>
            {eventRegions &&
              eventRegions.map(({ codRegiao: { codRegiao, Regiao } }) => (
                <Text key={codRegiao} region>
                  {Regiao}
                </Text>
              ))}
          </RegionContainer>
          {tipoUsuario === 'Noivo' &&
            principal === 1 &&
            avaliableRegions &&
            avaliableRegions.length && (
              <>
                <FormTitle>Comprar regiões</FormTitle>
                <StyledFormControl>
                  <MaterialInputLabel htmlFor="state-simple">
                    Região
                  </MaterialInputLabel>
                  <MaterialSelect
                    onChange={event =>
                      handleRegionSelectChange(event.target.value)
                    }
                    value={regionSelect}
                    inputProps={{
                      name: 'region',
                      id: 'region-simple',
                    }}
                  >
                    <MaterialMenuItem value={0}>Selecione</MaterialMenuItem>
                    {avaliableRegions &&
                      avaliableRegions.map(({ codRegiao, Regiao }) => (
                        <MaterialMenuItem key={codRegiao} value={codRegiao}>
                          {Regiao}
                        </MaterialMenuItem>
                      ))}
                  </MaterialSelect>
                </StyledFormControl>
                <ConfirmModal
                  opened={modalConfirmRegion}
                  closeModal={() => setModalConfirmRegion(false)}
                  onClickConfirm={() => addRegion()}
                  text={'Você tem certeza que quer comprar essa região?'}
                />
                <StyledButton
                  text="COMPRAR"
                  disabled={!isBuyRegionClickAvaliable}
                  click={() => setModalConfirmRegion(true)}
                />
              </>
            )}
          <Title ref={pointsHistory}>Histórico de pontos</Title>
          {pointHistory && pointHistory.length ? (
            pointHistory.map(({ id, value, balance, supplier, date }) => (
              <React.Fragment key={id}>
                <HistoryRowContainer>
                  <HistoryTitle style={{ width: '10%' }}>Valor</HistoryTitle>
                  <HistoryTitle style={{ width: '10%' }}>Saldo</HistoryTitle>
                  <HistoryTitle style={{ width: '40%' }}>
                    Fornecedor
                  </HistoryTitle>
                  <HistoryTitle style={{ width: '40%' }}>
                    Data/Hora
                  </HistoryTitle>
                </HistoryRowContainer>
                <HistoryRowContainer border>
                  <HistoryText style={{ width: '10%' }}>{value}</HistoryText>
                  <HistoryText style={{ width: '10%' }}>{balance}</HistoryText>
                  <HistoryText style={{ width: '40%' }}>{supplier}</HistoryText>
                  <HistoryText style={{ width: '40%' }}>
                    {moment(date)
                      .utc()
                      .format('DD/MM/YYYY HH:mm')}
                  </HistoryText>
                </HistoryRowContainer>
              </React.Fragment>
            ))
          ) : (
            <p>Sem histórico de pontos</p>
          )}
          <BalanceContainer>
            <BalanceText>Saldo:</BalanceText>
            <PointsText expired={!handleDateActive()}>
              {pointHistory && pointHistory.length > 0
                ? pointHistory[pointHistory.length - 1].balance
                : 0}
            </PointsText>
            {!handleDateActive() && (
              <PointsText> (Pontos expirados) </PointsText>
            )}
          </BalanceContainer>
          {tipoUsuario === 'Noivo' && (
            <StyledNoDecorationLink to="/app/noivos/premios">
              <StyledButton
                disabled={!handleDateActive()}
                text="UTILIZAR MEUS PONTOS"
              />
            </StyledNoDecorationLink>
          )}
        </>
      )}
      <StyledButton
        text={opened ? 'FECHAR DETALHES' : 'DETALHES'}
        opened={opened}
        click={() => setOpened(!opened)}
      />
      <ConfirmModal
        opened={modalVisible}
        closeModal={onClose}
        onClickConfirm={() => handleDisableEvent()}
        text={'Você tem certeza que quer desativar esse item ?'}
      />
      <AssetContainer bottom />
    </Wrapper>
  );
};

EventsCard.propTypes = {
  data: PropTypes.object.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};

export default EventsCard;
