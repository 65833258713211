import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import moment from 'moment';
import { supplierContent } from '../services/api';
import { toast } from 'react-toastify';
import DropdownContracts from './DropdownContracts';

const Title = styled.h2`
  color: ${theme.colors.grey3};
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: space-evenly;
  ${({ border }) => border && `border-bottom: 2px solid ${theme.colors.grey1};`}
`;

const HistoryTitle = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.6em;
  }

  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
  margin: 10px 0 3px 0;
`;

const HistoryText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.7em;
  }

  font-size: ${theme.fontSizeMed}px;
  color: ${theme.colors.grey3};
  margin: 0 0 10px 5px;
`;

const AmountContainer = styled.div`
  width: 90%;
  background-color: ${theme.colors.text_heading};
  display: flex;
  height: 65px;
  justify-content: center;
  align-items: center;
  margin: 2em 0 2em 0;
`;

const AmountText = styled.p`
  margin: 8px;
  color: ${theme.colors.white};
  font-size: ${theme.fontSizeMed}px;
`;

const AmountBold = styled.strong`
  color: ${theme.colors.primary};
`;

const SupplierPointsOutgoing = () => {
  const [totalPoints, setTotalPoints] = useState(null);
  const [history, setHistory] = useState(null);

  useFetch({
    url: urls.supplierContent.historyPoints,
    method: method.GET,
    condition: () => !totalPoints,
    callback: data => {
      if (data !== undefined) {
        setTotalPoints(data.saldo);
        setHistory(data.pointsHistory);
      } else {
        setTotalPoints(undefined);
      }
    },
  });

  const handleFilter = async contract => {
    const response =
      contract == 'all'
        ? await supplierContent.getHistoryPointsByContract()
        : await supplierContent.getHistoryPointsByContract(contract);
    if (response.ok && response.data) {
      const { saldo, pointsHistory } = response.data;
      setHistory(pointsHistory);
      setTotalPoints(saldo);
    } else {
      toast.error('Erro ao filtrar por contrato.');
    }
  };

  return (
    <>
      <Title>Histórico</Title>
      <DropdownContracts
        onSelectItem={item => handleFilter(item)}
        placeholder={'Filtrar por contrato'}
      />
      {history &&
        history.map(({ id, value, type, bride, date }) => (
          <React.Fragment key={id}>
            <RowContainer>
              <HistoryTitle style={{ width: '15%' }}>Valor</HistoryTitle>
              <HistoryTitle style={{ width: '40%' }}>Evento</HistoryTitle>
              <HistoryTitle style={{ width: '45%' }}>Data/Hora</HistoryTitle>
            </RowContainer>
            <RowContainer border>
              <HistoryText style={{ width: '15%' }}>
                {type === 'D' ? '-' : '+'}
                {value}
              </HistoryText>
              <HistoryText style={{ width: '40%' }}>{bride}</HistoryText>
              <HistoryText style={{ width: '45%' }}>
                {moment(date).format('DD/MM/YYYY HH:mm:ss')}
              </HistoryText>
            </RowContainer>
          </React.Fragment>
        ))}
      <AmountContainer>
        <AmountText>
          Saldo: <AmountBold>{totalPoints} pontos</AmountBold>
        </AmountText>
      </AmountContainer>
    </>
  );
};

export default SupplierPointsOutgoing;
