import React, { useState } from 'react';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

import {
  Button,
  MaterialDatePicker,
  MaterialInputLabel,
  MaterialInput,
  MoneyMask,
} from './';
import { theme } from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import ModalContainer from './ModalContainer';
import { invitedNumber } from '../utils/dropdownValues';
import moment from 'moment';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: ${({ tab }) => (tab ? 'space-between ' : 'space-around')};
  align-items: center;
  width: ${({ tab }) => (tab ? '95%' : '100%')};
  ${({ tab }) =>
    tab &&
    `
    border-bottom: 1px solid ${theme.colors.grey2}
    margin-bottom: 0.5em;
  `}
`;

const TabName = styled.h4`
  @media ${medias.phoneMax} {
    font-size: 0.65em;
  }

  font-size: ${theme.fontSize * 0.94}px;
  color: ${theme.colors.text_heading};
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  background-color: ${theme.colors.text_heading};
  border: 2px solid ${theme.colors.text_heading};
  color: white;
  height: 2em;
  width: 45%;
  margin-top: 1em;
  padding: 0;
`;

const StyledFormControl = styled(FormControl)`
  @media ${medias.phoneMax} {
    width: 90%;
  }

  width: 50%;
`;

const StyledDatePicker = styled(props => <MaterialDatePicker {...props} />)`
  width: 45%;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 3em;
`;

const Arrow = styled.span`
  color: ${theme.colors.text_heading};
  font-weight: bold;
  cursor: pointer;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
`;

const ModalTabs = ({ onSelectTab, onChangePage }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTab = id => {
    setSelectedTab(id);
    onSelectTab(id);
  };

  const renderTabs = () => {
    if (currentPage === 0) {
      return (
        <>
          <TabName selected={selectedTab === 0} onClick={() => handleTab(0)}>
            SEGMENTO
          </TabName>
          <TabName selected={selectedTab === 1} onClick={() => handleTab(1)}>
            REGIÃO
          </TabName>
          <TabName selected={selectedTab === 2} onClick={() => handleTab(2)}>
            DATA DO EVENTO
          </TabName>
        </>
      );
    }

    if (currentPage === 1) {
      return (
        <>
          <TabName selected={selectedTab === 0} onClick={() => handleTab(0)}>
            CADASTRO
          </TabName>
          <TabName selected={selectedTab === 1} onClick={() => handleTab(1)}>
            FORNECEDOR
          </TabName>
          <TabName selected={selectedTab === 2} onClick={() => handleTab(2)}>
            CÓD. EVENTO
          </TabName>
        </>
      );
    }

    if (currentPage === 2) {
      return (
        <>
          <TabName selected={selectedTab === 0} onClick={() => handleTab(0)}>
            LOCAL DA RECEPÇÃO
          </TabName>
          <TabName selected={selectedTab === 1} onClick={() => handleTab(1)}>
            CONVIDADOS
          </TabName>
          <TabName selected={selectedTab === 2} onClick={() => handleTab(2)}>
            INVESTIMENTO
          </TabName>
        </>
      );
    }
  };

  const handlePageChange = type => {
    if (type === 0) {
      if (currentPage > 0) {
        setCurrentPage(currentPage - 1);
        onChangePage(currentPage - 1);
        handleTab(0);
        return;
      }
    }

    if (currentPage < 2) {
      setCurrentPage(currentPage + 1);
      onChangePage(currentPage + 1);
      handleTab(0);
    }
  };

  return (
    <>
      <RowContainer tab>
        <Arrow onClick={() => handlePageChange(0)}>{'<'}</Arrow>
        <TabContainer>{renderTabs()}</TabContainer>
        <Arrow onClick={() => handlePageChange(1)}>{'>'}</Arrow>
      </RowContainer>
    </>
  );
};

const BrideFilterModal = ({
  closeModal,
  opened,
  onClickFilter,
  onClickClear,
  segmentsData,
  regionsData,
  suppliersData,
  loading,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  const [segment, setSegment] = useState('');
  const [region, setRegion] = useState('');
  const [supplier, setSupplier] = useState('');
  const [reception, setReception] = useState('');
  const [invited, setInvited] = useState('');
  const [eventCode, setEventCode] = useState('');
  const [weddingDate, setWeddingDate] = useState({
    from: new Date(),
    to: new Date(),
  });
  const [registerDate, setRegisterDate] = useState({
    from: new Date(),
    to: new Date(),
  });
  const [investment, setInvestment] = useState({
    from: '',
    to: '',
  });

  const cleanForm = () => {
    setSegment('');
    setRegion('');
    setSegment('');
    setReception('');
    setInvited('');
    setSupplier('');
    setWeddingDate({
      from: new Date(),
      to: new Date(),
    });
    setRegisterDate({
      from: new Date(),
      to: new Date(),
    });
    setInvestment({ from: '', to: '' });
    setEventCode('');
    onClickClear();
  };

  const handleFilter = () => {
    if (selectedPage === 0) {
      if (selectedTab === 0) {
        const data = segment;
        onClickFilter('segment', data);
        return;
      }

      if (selectedTab === 1) {
        const data = region;
        onClickFilter('region', data);
        return;
      }

      if (selectedTab === 2) {
        const data = weddingDate;
        onClickFilter('weddingDate', data);
        return;
      }
    }

    if (selectedPage === 1) {
      if (selectedTab === 0) {
        const data = registerDate;
        onClickFilter('register', data);
        return;
      }

      if (selectedTab === 1) {
        const data = supplier;
        onClickFilter('supplier', data);
        return;
      }

      if (selectedTab === 2) {
        const data = eventCode;
        onClickFilter('eventCode', data);
        return;
      }
    }

    if (selectedPage === 2) {
      if (selectedTab === 0) {
        const data = reception;
        onClickFilter('reception', data);
        return;
      }

      if (selectedTab === 1) {
        const data = invited;
        onClickFilter('invited', data);
        return;
      }

      if (selectedTab === 2) {
        const data = investment;
        onClickFilter('investment', data);
        return;
      }
    }
  };

  const renderFirstPage = () => {
    if (selectedTab === 0) {
      return (
        <>
          <StyledFormControl>
            <MaterialInputLabel htmlFor="segment-native-simple">
              Selecione o segmento
            </MaterialInputLabel>
            <NativeSelect
              onChange={event => setSegment(event.target.value)}
              value={segment}
              inputProps={{
                name: 'segment',
                id: 'segment-native-simple',
              }}
            >
              <option value="" />
              {segmentsData &&
                segmentsData.map(({ segment, codSegment }) => (
                  <option value={codSegment} key={codSegment}>
                    {segment}
                  </option>
                ))}
            </NativeSelect>
          </StyledFormControl>
        </>
      );
    } else if (selectedTab === 1) {
      return (
        <>
          <StyledFormControl>
            <MaterialInputLabel htmlFor="segment-native-simple">
              Selecione a região
            </MaterialInputLabel>
            <NativeSelect
              onChange={event => setRegion(event.target.value)}
              value={region}
              inputProps={{
                name: 'segment',
                id: 'segment-native-simple',
              }}
            >
              <option value="" />
              {regionsData &&
                regionsData.map(({ region, codRegion }) => (
                  <option value={codRegion} key={codRegion}>
                    {region}
                  </option>
                ))}
            </NativeSelect>
          </StyledFormControl>
        </>
      );
    } else if (selectedTab === 2) {
      return (
        <RowContainer>
          <StyledDatePicker
            minDate={moment().subtract(30, 'days')}
            label="De"
            format={'DD/MM/YYYY'}
            value={weddingDate.from}
            onChange={date => setWeddingDate({ ...weddingDate, from: date })}
          />
          <StyledDatePicker
            disablePast
            label="Até"
            format={'DD/MM/YYYY'}
            value={weddingDate.to}
            onChange={date => setWeddingDate({ ...weddingDate, to: date })}
          />
        </RowContainer>
      );
    }
  };

  const renderSecondPage = () => {
    if (selectedTab === 0) {
      return (
        <RowContainer>
          <StyledDatePicker
            label="De"
            format={'DD/MM/YYYY'}
            value={registerDate.from}
            onChange={date => setRegisterDate({ ...registerDate, from: date })}
          />
          <StyledDatePicker
            label="Até"
            format={'DD/MM/YYYY'}
            value={registerDate.to}
            onChange={date => setRegisterDate({ ...registerDate, to: date })}
          />
        </RowContainer>
      );
    } else if (selectedTab === 1) {
      return (
        <StyledFormControl>
          <MaterialInputLabel htmlFor="supplier-native-simple">
            Selecione o fornecedor
          </MaterialInputLabel>
          <NativeSelect
            onChange={event => setSupplier(event.target.value)}
            value={supplier}
            inputProps={{
              name: 'supplier',
              id: 'supplier-native-simple',
            }}
          >
            <option value="" />
            {suppliersData &&
              suppliersData.map(({ supplierName, id }) => (
                <option value={id} key={id}>
                  {supplierName}
                </option>
              ))}
          </NativeSelect>
        </StyledFormControl>
      );
    } else if (selectedTab === 2) {
      return (
        <MaterialInput
          label="Código do Evento"
          style={{ width: '50%' }}
          value={eventCode}
          type="number"
          onChange={e => setEventCode(e.target.value)}
        />
      );
    }
  };

  const renderThirdPage = () => {
    if (selectedTab === 0) {
      return (
        <MaterialInput
          label="Local da recepção"
          style={{ width: '50%' }}
          value={reception}
          onChange={e => setReception(e.target.value)}
        />
      );
    } else if (selectedTab === 1) {
      return (
        <StyledFormControl>
          <MaterialInputLabel htmlFor="invited-native-simple">
            Número de convidados
          </MaterialInputLabel>
          <NativeSelect
            onChange={event => setInvited(event.target.value)}
            value={invited}
            inputProps={{
              name: 'invited',
              id: 'invited-native-simple',
            }}
          >
            <option value="" />
            {invitedNumber.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </NativeSelect>
        </StyledFormControl>
      );
    } else if (selectedTab === 2) {
      return (
        <RowContainer>
          <MaterialInput
            label="De"
            style={{ width: '40%' }}
            value={investment.from}
            onChange={e =>
              setInvestment({ ...investment, from: e.target.value })
            }
            InputProps={{
              inputComponent: MoneyMask,
            }}
          />
          <MaterialInput
            label="até"
            style={{ width: '40%' }}
            value={investment.to}
            onChange={e => setInvestment({ ...investment, to: e.target.value })}
            InputProps={{
              inputComponent: MoneyMask,
            }}
          />
        </RowContainer>
      );
    }
  };

  const renderContent = () => {
    if (selectedPage === 0) {
      return renderFirstPage();
    }
    if (selectedPage === 1) {
      return renderSecondPage();
    }
    if (selectedPage === 2) {
      return renderThirdPage();
    }
  };

  return (
    <ModalContainer opened={opened} closeModal={closeModal} hasArabesco={true}>
      <ModalTabs
        onSelectTab={id => setSelectedTab(id)}
        onChangePage={id => setSelectedPage(id)}
      />
      <Wrapper>
        <RowContainer>
          <Container>{renderContent()}</Container>
        </RowContainer>
        <RowContainer>
          <StyledButton text="LIMPAR" click={cleanForm} />
          <StyledButton
            text={loading ? 'CARREGANDO...' : 'FILTRAR'}
            click={handleFilter}
          />
        </RowContainer>
      </Wrapper>
    </ModalContainer>
  );
};

export default BrideFilterModal;
