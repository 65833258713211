import React, { useState } from 'react';

import styled from 'styled-components';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { Button, CardContainer, ConfirmModal, AdvantagesDetails } from './';
import { WhiteTrashIcon } from './icons';
import Grid from '@material-ui/core/Grid';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  margin: 0 0 1em 0;
`;

const DefaultText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.75em;
  }

  font-size: ${theme.fontSizeMed}px;
  color: ${theme.colors.grey3};
  margin: 4px;
  font-weight: bold;
`;

const SegmentContainer = styled.div`
  @media ${medias.phoneMax} {
    width: 50%;
    margin-left: 30px;
  }
  margin-top: 5px;
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: flex-start;
`;

const StyledIcon = styled.img`
  width: 30px;
  height: 30px;
  fill: ${({ color }) => color};
`;

const ItemText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.65em;
    width: 40%;
  }

  display: flex;
  align-self: center;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.text_heading};
  margin: 0 0 0 0.5em;
  word-wrap: break-word;
`;

const HalfButton = styled(props => <Button {...props} />)`
  @media ${medias.phoneMax} {
    height: 40px;
    font-size: ${theme.fontSizeSm}px;
  }
  background-color: ${props =>
    props.selected ? theme.colors.white : theme.colors.text_heading};
  color: ${props =>
    props.selected ? theme.colors.text_heading : theme.colors.white};
  border-color: ${theme.colors.text_heading};
  width: 100%;
  font-size: ${theme.fontSizeSm}px;
  text-align: center;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const StyledButton = styled.div`
  width: 100%;
  text-align: center;
  padding: 0.3em 1em;
  background-color: #ff2d55;
  cursor: pointer;
  border-radius: 3px;
`;

const Line = styled.div`
  height: 3px;
  width: 100%;
  background-color: ${theme.colors.grey1};
  margin: 10px 0 20px 0;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: auto;
`;

const Image = styled.img`
  width: 100%;
`;

const SubTitle = styled.span`
  @media ${medias.phoneMax} {
    font-size: ${theme.fontSizeSm}px;
    margin-left: 5px;
  }
  font-size: ${theme.fontSize}px;
  color: ${theme.colors.grey3};
  font-weight: 300;
  text-align: left;
  margin-bottom: 0.5em;
`;

const Savings = styled.span`
  font-size: ${theme.fontSize}px;
  color: ${theme.colors.primary};
  font-weight: bold;
  text-align: left;
  margin-left: 0.5em;
`;

const SupplierAdvantagesCard = ({
  data,
  type,
  changeInativation,
  deleteAdvantage,
  changeAdvantage,
}) => {
  const {
    codAdvantage,
    name,
    region,
    codSegment,
    segment,
    link,
    image,
    description,
    discount,
    resume,
  } = data;
  const [modal, setModalOpen] = useState(false);
  const [detailsOpened, setDetailsOpened] = useState(false);

  return (
    <Wrapper>
      <ConfirmModal
        opened={modal}
        closeModal={() => setModalOpen(false)}
        onClickConfirm={() => deleteAdvantage(codAdvantage, type)}
        text={'Você tem certeza que quer deletar esse benefício?'}
      />
      <CardContainer>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <ImageContainer>
              {image && <Image src={image} alt="Advantage Image" />}
            </ImageContainer>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <DefaultText>{name}</DefaultText>
            <ItemText>{region}</ItemText>
            {codSegment && (
              <SegmentContainer key={codSegment}>
                <StyledIcon src={link} />
                <ItemText>{segment}</ItemText>
              </SegmentContainer>
            )}
            {discount && (
              <SubTitle>
                Economia de
                <Savings>R$ {discount}</Savings>
              </SubTitle>
            )}
          </Grid>
        </Grid>
        {type == 'analysis' ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <span style={{ fontWeight: 'bold' }}>
                Esperando confirmação do VDB
              </span>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <HalfButton
                text={'INATIVAR'}
                click={() => changeInativation(codAdvantage)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <StyledButton
                style={{ cursor: 'pointer' }}
                onClick={() => setModalOpen(true)}
              >
                <WhiteTrashIcon />
              </StyledButton>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              <HalfButton
                text="DETALHES"
                click={() => setDetailsOpened(!detailsOpened)}
                selected={detailsOpened}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <HalfButton text="ALTERAR" click={() => changeAdvantage(data)} />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <HalfButton
                text={type == 'inative' ? 'ATIVAR' : 'INATIVAR'}
                click={() => changeInativation(codAdvantage)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <StyledButton
                style={{ cursor: 'pointer' }}
                onClick={() => setModalOpen(true)}
              >
                <WhiteTrashIcon />
              </StyledButton>
            </Grid>
          </Grid>
        )}
        {type !== 'analysis' && <Line />}
        {detailsOpened && (
          <AdvantagesDetails
            moreDiscount={discount.toFixed(2)}
            details={description}
            summary={resume.split('<br />')}
          />
        )}
      </CardContainer>
    </Wrapper>
  );
};

export default SupplierAdvantagesCard;
