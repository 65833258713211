import React, { useEffect, useState, useCallback } from 'react';
//import { useFormState } from 'react-use-form-state';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import { toast } from 'react-toastify';

import { bride } from '../services/api';
import { urls } from '../services/urls';
import useFetch, { method } from '../hooks/useFetch';
import { siteContent } from '../services/api';
import phoneMask from '../utils/phoneMask';

import {
  MaterialInput,
  MaterialDatePicker,
  MaterialSelect,
  MaterialMenuItem,
  MaterialInputLabel,
  Button,
} from '../components';
import { theme } from '../styles/theme';
import { useStateValue } from '../state';
import { InputAdornment } from '@material-ui/core';
import {
  AccountCircle,
  MailOutlineRounded,
  PhoneAndroidOutlined,
} from '@material-ui/icons';
import { HomePhoneIcon, MessageIcon } from './icons';
import { RadioInput, CheckBox } from '../components/styled';
import WhatsApp from '@material-ui/icons/WhatsApp';

const FormTitle = styled.p`
  text-align: center;
  font-size: ${theme.fontSizeMed}px;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  ::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -0.5em;
    transform: translateX(-50%);
    width: 75%;
    height: 3px;
    background: ${theme.colors.primary};
  }
`;

const RadioSection = styled.fieldset`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: none;
  width: 90%;
  padding: 0;
  margin: 1em 0;
  align-items: center;
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 0.1em;
  padding: 0.1em 0.6em;
  width: 100%;
`;

/*const RadioInput = styled.input`
  margin: 0;
  margin-right: 0.3em;
`;*/

const RadioLabel = styled.label`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeSm}px;
  text-align: center;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const SelectGroup = styled(props => <FormControl {...props} />)`
  display: flex;
  flex-direction: column;
  width: 47.5%;
`;

/*const StyledLabel = styled.label`
  color: ${theme.colors.grey3};
  font-size: ${theme.fontSizeSm}px;
  margin: 0;
  text-align: left;
`;*/

const ContactLabel = styled.span`
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
  font-weight: 300;
  margin-right: 1em;
  margin-bottom: 0.2em;
`;

const InputsSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: none;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-bottom: 0.5em;
`;

const StyledDatePicker = styled(props => <MaterialDatePicker {...props} />)`
  margin: 0.6rem 0;
  width: 100%;
`;

const StyledButton = styled(props => <Button {...props} />)`
  width: 47.5%;
  background-color: ${theme.colors.text_heading};
  border-color: ${theme.colors.text_heading};
  color: white;
  font-size: ${theme.fontSizeSm}px;
  &:hover {
    background-color: ${theme.colors.primary};
    color: ${theme.colors.text_heading};
  }
`;

const ButtonsContainer = styled.section`
  display: flex;
  justify-content: space-around;
  margin-top: 0.5em;
  width: 100%;
`;

const StyledForm = styled.form`
  width: 90%;
`;

const StyledFieldset = styled.div`
  width: 100%;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: ${({ noTop }) => (noTop ? `0 0 0.5em 0` : `0.25em 0 0 0`)};
`;

/*const Checkbox = styled.input`
  margin-right: 0.5em;

  &:checked:after {
    background-color: ${theme.colors.primary};
    color: ${theme.colors.primary};
  }
`;*/

const StyledSpan = styled.p`
  margin: 0;
  width: 30%;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
`;

const RequestBudgetForm = ({ supplierCod }) => {
  const { userId } = localStorage;

  const [authenticated, setAuthentication] = useState(false);
  const [contact, setContact] = useState(false);
  const [availableCities, setAvailableCities] = useState(null);

  /*const initialFormState = {
    codContactMe: '1',
    uf: 0,
    city: 0,
  };*/

  const [
    {
      /*forms: { requestBudgetForm },*/
      generalData: { states },
      session: { selectedEvent },
    },
    dispatch,
  ] = useStateValue();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [weddingGuests, setWeddingGuests] = useState('');
  const [codIBGE, setCodIBGE] = useState('');
  const [codContactMe, setCodContactMe] = useState('2');
  const [cerimonyLocation, setCerimonyLocation] = useState('');
  const [message, setMessage] = useState('');
  const [codUf, setCodUf] = useState(false);
  const [eventDate, setEventDate] = useState(new Date());
  const [dateUndefined, setDateUndefined] = useState(false);

  const clearForm = () => {
    setName('');
    setEmail('');
    setPhone('');
    setWeddingGuests('');
    setCodIBGE('');
    setCodContactMe('');
    setCerimonyLocation('');
    setMessage('');
    setCodUf(false);
    if (!authenticated) {
      setEventDate(new Date());
      setDateUndefined(false);
    }
  };

  const checkAuthentication = useCallback(() => {
    userId ? setAuthentication(true) : setAuthentication(false);
  }, [userId]);

  /*useEffect(() => {
    dispatch({
      type: 'changeBudgetForm',
      field: 'codContactMe',
      newText: '1',
    });
  });*/

  useEffect(() => checkAuthentication(), [checkAuthentication]);

  /*const [formState, { text, radio, select, label }] = useFormState(
    initialFormState,
    {
      withIds: true,
      onChange(e) {
        const { name, value } = e.target;

        if (name !== 'phone') {
          dispatch({
            type: 'changeBudgetForm',
            field: name,
            newText: value,
          });
        }
      },
    }
  );*/

  useFetch({
    url: urls.siteContent.states,
    method: method.GET,
    condition: () => !states,
    callback: data =>
      dispatch({
        type: 'setStates',
        newStates: data,
      }),
  });

  const findCurrentEvent = e => {
    let event = e.filter(event => event.codEvento === selectedEvent);
    return event[0];
  };

  const authenticatedFormHandler = async () => {
    const response = await bride.userInfo();
    let payload = response.data;
    let event = findCurrentEvent(payload.events);
    const { codIBGE, email, name, phone } = payload;
    const { eventDate, weddingGuests, cerimonyLocation } = event;
    const body = {
      city: codIBGE,
      name,
      email,
      phone,
      weddingGuests,
      codContactMe,
      cerimonyLocation,
      eventDate,
      codSupplier: supplierCod,
      message,
    };
    return body;
  };

  const notAuthenticatedFormHandler = () => {
    let newDate;
    if (eventDate) newDate = eventDate.toISOString('YYYY-MM-DD').split('T');

    const contact = parseInt(codContactMe);
    const numberWeddingGuests = parseInt(weddingGuests);
    console.log(numberWeddingGuests);
    if (isNaN(weddingGuests)) {
      return toast.info(
        'O número de convidados deve ser um número. Tente novamente'
      );
    }

    const body = {
      name,
      email,
      phone,
      weddingGuests: numberWeddingGuests,
      eventDate: eventDate && !dateUndefined ? newDate[0] : null,
      codSupplier: supplierCod,
      contact,
      message,
      city: codIBGE,
      cerimonyLocation,
    };

    return body;
  };

  const sendBudget = async () => {
    if (authenticated) {
      const body = await authenticatedFormHandler();
      const response = await bride.budget(body);
      response.ok && response.data
        ? toast.success('Orçamento enviado com sucesso!')
        : toast.error('Não foi possivel enviar o orçamento!');
      clearForm();
      return;
    }
    const body = await notAuthenticatedFormHandler();
    const response = await siteContent.budget(body);
    response.ok && response.data
      ? toast.success('Orçamento enviado com sucesso!')
      : toast.error('Não foi possivel enviar o orçamento!');
    clearForm();
    return;
  };

  const handlePhoneChange = input => {
    const newText = phoneMask(input);
    setPhone(newText);
    /*dispatch({
      type: 'changeBudgetForm',
      field: 'phone',
      newText,
    });*/
  };

  /*const handleChange = (newText, field) => {
    dispatch({
      type: 'changeBudgetForm',
      newText,
      field,
    });
  };*/

  const fetchCities = async codUf => {
    setCodUf(codUf);
    const response = await siteContent.cities(codUf);
    response.ok && response.data
      ? setAvailableCities(response.data)
      : toast.error('Não foi possivel recuperar as cidades!');
  };

  return (
    <StyledForm /*onSubmit={() => console.log(formState)}*/>
      {!authenticated && (
        <>
          <FormTitle>Solicitar orçamento</FormTitle>
          <div style={{ textAlign: 'center', fontSize: '12px' }}>
            (Campos com * são obrigatórios)
          </div>
          <MaterialInput
            onChange={e => setName(e.target.value)}
            value={name}
            style={{ width: '100%' }}
            required
            label="Seu nome"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <AccountCircle style={{ color: theme.colors.primary }} />
                </InputAdornment>
              ),
            }}
          />
          <MaterialInput
            onChange={e => setEmail(e.target.value)}
            value={email}
            required
            xs={9}
            label="Seu melhor e-mail"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <MailOutlineRounded style={{ color: theme.colors.primary }} />
                </InputAdornment>
              ),
            }}
          />
          <MaterialInput
            onChange={event => handlePhoneChange(event.target.value)}
            value={phone}
            required
            style={{ width: '100%' }}
            label="Telefone"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <PhoneAndroidOutlined
                    style={{ color: theme.colors.primary }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <StyledDatePicker
            label="Data do evento"
            format={'DD/MM/YYYY'}
            disablePast
            disabled={dateUndefined}
            value={eventDate}
            onChange={e => setEventDate(e)}
          />
          <StyledFieldset noTop>
            <CheckBox
              name="hasDate"
              label="Não definida"
              onChange={() => setDateUndefined(!dateUndefined)}
              checked={dateUndefined}
            />
            <StyledSpan>Não definida</StyledSpan>
          </StyledFieldset>
          <InputsSection>
            <SelectGroup>
              <MaterialInputLabel htmlFor="uf">Estado*</MaterialInputLabel>
              <MaterialSelect
                value={codUf}
                name="uf"
                onChange={e => fetchCities(e.target.value)}
              >
                <MaterialMenuItem value={0}>Selecione</MaterialMenuItem>
                {states
                  ? states.map(({ name, cod }) => (
                      <MaterialMenuItem key={cod} value={cod}>
                        {name}
                      </MaterialMenuItem>
                    ))
                  : null}
              </MaterialSelect>
            </SelectGroup>
            <SelectGroup>
              <MaterialInputLabel shrink htmlFor="city">
                Cidade*
              </MaterialInputLabel>
              <MaterialSelect
                value={codIBGE}
                name="city"
                onChange={event => setCodIBGE(event.target.value)}
                displayEmpty
              >
                <MaterialMenuItem value={0}>Selecione estado</MaterialMenuItem>
                {availableCities
                  ? availableCities.map(({ nomeCidade, codCidadeIBGE }) => (
                      <MaterialMenuItem
                        key={codCidadeIBGE}
                        value={codCidadeIBGE}
                      >
                        {nomeCidade}
                      </MaterialMenuItem>
                    ))
                  : null}
              </MaterialSelect>
            </SelectGroup>
          </InputsSection>
          <InputsSection>
            <MaterialInput
              onChange={e => setCerimonyLocation(e.target.value)}
              required
              value={cerimonyLocation}
              style={{ width: '47.5%' }}
              label="Local do evento"
            />
            <MaterialInput
              onChange={e => setWeddingGuests(e.target.value)}
              required
              value={weddingGuests}
              style={{ width: '47.5%' }}
              label="Nº de convidados"
            />
          </InputsSection>
        </>
      )}
      <MaterialInput
        onChange={e => setMessage(e.target.value)}
        value={message}
        style={{ width: '100%' }}
        label="Mensagem"
      />
      <RadioSection>
        <ContactLabel>Quero ser contatado*:</ContactLabel>
        <RadioGroup>
          <RadioInput
            checked={codContactMe === '3'}
            onClick={() => setCodContactMe('3')}
          />
          <RadioLabel>Whatsapp</RadioLabel>
          <WhatsApp fontSize="small" style={{ color: '#DBBAB1' }} />
        </RadioGroup>
        <RadioGroup>
          <RadioInput
            checked={codContactMe === '1'}
            onChange={() => setCodContactMe('1')}
          />
          <RadioLabel>Telefone</RadioLabel>
          <HomePhoneIcon />
        </RadioGroup>
        <RadioGroup>
          <RadioInput
            checked={codContactMe === '2'}
            onClick={() => setCodContactMe('2')} /*('codContactMe', '3')}*/
          />
          <RadioLabel>E-mail</RadioLabel>
          <MessageIcon />
        </RadioGroup>
      </RadioSection>
      {authenticated && (
        <StyledFieldset>
          <CheckBox
            name="hasDate"
            label="Não definida"
            onChange={() => setContact(!contact)}
            checked={contact}
          />
          <StyledSpan>Já entrei em contato com esse fornecedor</StyledSpan>
        </StyledFieldset>
      )}
      <ButtonsContainer>
        <StyledButton
          text="SOLICITAR"
          click={() => {
            if (
              !authenticated &&
              (!name ||
                !email ||
                !phone ||
                !weddingGuests ||
                !codIBGE ||
                !codContactMe)
            ) {
              toast.error('Preencha todos os dados obrigatórios!');
            } else {
              sendBudget();
            }
          }}
        />
      </ButtonsContainer>
    </StyledForm>
  );
};

export default RequestBudgetForm;
