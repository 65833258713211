import React, { useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { theme } from '../styles/theme';
// import { medias } from '../utils/mediaQueries';
import Arabesco from '../images/arabescosModal.svg';
import { useStateValue } from '../state';
import { RedButton } from '../components/styled';
import { ExitButton } from '../components/styled';
import { REGEX_EMAIL } from '../utils/regex';
import { forgetPassoword } from '../services/api';

const Wrapper = styled.div`
  position: absolute;
  width: 90%;
  min-height: 45%;

  background-color: ${theme.colors.grey1};
  border: 1px solid ${theme.colors.grey2};
  z-index: 5;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
`;

const AssetContainer = styled.div`
  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  ${({ bottom }) => (bottom ? 'margin-top: 1em;' : 'margin-bottom: 1em;')}
`;

const InnerContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: center;
  width: 90%;
`;

const Line = styled.div`
  height: 2px;
  background-color: ${theme.colors.grey2};
  margin: 0 0 35px 0;
`;

const StyledInput = styled.input`
  background-color: transparent;
  border: none;
`;

const Title = styled.h4`
  text-align: left;
  margin: 0;
`;

const InputTitle = styled.p`
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
  text-align: left;
  margin: 10px 0 5px 0;
`;

const Text = styled.p`
  font-size: ${theme.fontSize * 1.13}px;
  text-align: left;
  margin: 15px 0 15px 0;
`;

const PasswordModal = () => {
  const [{ visibility, forms }, dispatch] = useStateValue();
  const [loading, setLoading] = useState(false);

  const handleChange = useCallback(
    (newText, field) => {
      dispatch({
        type: 'changeLoginForm',
        newText,
        field,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    return () => handleChange('', 'forgotPasswordEmail');
  }, [handleChange]);

  const changePassword = async () => {
    const payload = {
      email: forms.loginForm.forgotPasswordEmail,
    };

    const response = await forgetPassoword.forgetPassword(payload);

    response.ok
      ? toast.success(response.data.message)
      : toast.error('Esse email não existe na nossa base');
    setLoading(false);
  };

  const onClickConfirm = () => {
    setLoading(true);
    const destinationAddress = forms.loginForm.forgotPasswordEmail;

    if (!destinationAddress) {
      toast.error('Favor inserir seu endereço de e-mail!');
      setLoading(false);
      return false;
    }

    if (!REGEX_EMAIL.test(destinationAddress)) {
      toast.error('Digite um e-mail válido!');
      setLoading(false);
      return false;
    }
    changePassword();
  };

  const onClickClose = () => {
    dispatch({
      type: 'togglePasswordModal',
      newVisibility: false,
    });
  };

  return (
    <>
      {visibility.passwordModal && (
        <Wrapper>
          <AssetContainer>
            <ExitButton onClick={onClickClose} left={'92%'} top={'5%'} />
          </AssetContainer>
          <InnerContainer>
            <Title>ALTERAR A SENHA</Title>
            <Text>Entre com o email para recuperar a senha:</Text>
            <InputTitle>Email</InputTitle>
            <StyledInput
              value={forms.loginForm.forgotPasswordEmail}
              type="text"
              onChange={event =>
                handleChange(event.target.value, 'forgotPasswordEmail')
              }
            />
            <Line />
            {!loading && <RedButton text="CONFIRMAR" click={onClickConfirm} />}
          </InnerContainer>
        </Wrapper>
      )}
    </>
  );
};

export default PasswordModal;
