import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
//import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { toast } from 'react-toastify';

import ModalContainer, { ModalTitle } from './ModalContainer';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { MaterialInput } from '.';
import {
  MaterialInputLabel,
  MaterialSelect,
  MaterialMenuItem,
  MoneyMask,
  EditBrideModal,
} from '../components';
import { supplier } from '../services/api';
import dateUndefined from '../utils/undefinedDate';
//import { ListDivisor } from './styled';
import { ContractIcon } from './icons/SupplierIcons';

const StyledContractIcon = styled.span`
  cursor: pointer;
  svg {
    font-weight: bold;
    width: 1.55em;
    max-height: 1.5em;
    margin-right: 0.35em;
    fill: ${theme.colors.primary};
  }
`;

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto;
  grid-gap: 1em 0.25em;
  border: 0.2px solid ${theme.colors.text_heading};
  border-radius: 0.2em;
  padding: 0.5rem 0 0;
  margin-bottom: 1.5rem;
  align-items: center;
  svg {
    max-width: 1.5em;
    max-height: 1.5em;
    fill: ${theme.colors.primary};
  }
  .button-page {
    background: ${theme.colors.secondary};
    color: white;
    padding: 0.5em 1em;
    border-radius: 0.25em;
  }
  h2 {
    font-weight: bold;
    font-size: 1em;
    margin: 0;
  }
  .contract-success {
    text-align: center;
    font-weight: bold;
  }
  .contract-form {
    position: relative;
    padding: 0 0 2.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn_exit {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: transparent;
    width: auto;
    color: ${theme.colors.grey3};
    font-size: 0.85em;
  }

  @media ${medias.phoneMin} {
    grid-template-columns: auto auto;
    button,
    .contract-form,
    .contract-success {
      grid-area: 4 / 1 / 4 / -1;
    }
  }
  @media ${medias.tabletMin} {
    grid-gap: 1em 0.5em;
    grid-area: 3 / 1 / 3 / -1;
    grid-template-columns: repeat(3, auto);
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StyledFormControl = styled(FormControl)`
  width: 50%;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const EditSpan = styled.span`
  font-size: ${theme.fontSizeSm} - 1px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const GenerateContractModal = ({
  onCloseModal,
  opened,
  segmentData,
  regionsData,
  clientEmail,
  updateOportunities,
  brideId,
}) => {
  const [value, setValue] = useState('');
  const [segment, setSegment] = useState('');
  const [region, setRegion] = useState('');
  const [sendActionCount, setSendActionCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (!region || !segment || !value) {
      toast.error('Preencha todos os campos!');
      return;
    }
    setIsLoading(true);
    if (sendActionCount === 0) {
      const val = value.split('.');
      const verif = await supplier.checkContractValue(
        val[0],
        val[1],
        brideId.toString()
      );
      if (!verif.ok) {
        setIsLoading(false);
        return toast.error('Falha ao verificar contratos.');
      }
      // eslint-disable-next-line no-const-assign
      setSendActionCount(sendActionCount + 1);
      if (verif.data.contractAmount > 0) {
        setIsLoading(false);
        return toast.info(
          'Há um contrato registrado com mesmo o mesmo evento. Assegure-se de que deseja continuar.',
          { autoClose: false }
        );
      }
    }

    const payload = {
      value,
      codRegion: region,
      codSegment: segment,
      email: clientEmail,
    };

    toast.info('Aguarde... Seu contrato está sendo gerado.');
    const res = await supplier.inviteBrides(payload);
    if (!res.ok) {
      setIsLoading(false);
      return toast.error('Falha ao gerar contrato.');
    }
    setIsLoading(false);
    setValue('');
    setSegment('');
    setRegion('');
    onCloseModal();
    updateOportunities();
    return toast.success('Contrato gerado com sucesso!', { autoClose: 9500 });
  };

  return (
    <Wrapper>
      <ModalContainer opened={opened} closeModal={onCloseModal} hasArabesco>
        <ModalWrapper>
          <ModalTitle>REGISTRAR CONTRATO</ModalTitle>

          <MaterialInput
            style={{ width: '100%' }}
            label="Valor do contrato"
            value={value}
            onChange={event => setValue(event.target.value)}
            InputProps={{
              inputComponent: MoneyMask,
            }}
          />
          <Row>
            <StyledFormControl style={{ width: '45%' }}>
              <MaterialInputLabel htmlFor="region">Região</MaterialInputLabel>
              <MaterialSelect
                onChange={event => setRegion(event.target.value)}
                value={region}
                inputProps={{
                  name: 'region',
                  id: 'region',
                }}
              >
                {regionsData &&
                  regionsData.map(({ region, codRegion }) => (
                    <MaterialMenuItem value={codRegion} key={codRegion}>
                      {region}
                    </MaterialMenuItem>
                  ))}
              </MaterialSelect>
            </StyledFormControl>

            <StyledFormControl>
              <MaterialInputLabel htmlFor="segment">
                Segmento
              </MaterialInputLabel>
              <MaterialSelect
                onChange={event => setSegment(event.target.value)}
                value={segment}
                inputProps={{
                  name: 'segment',
                  id: 'segment',
                }}
              >
                {segmentData &&
                  segmentData.map(({ segment, codSegment }) => (
                    <MaterialMenuItem value={codSegment} key={codSegment}>
                      {segment}
                    </MaterialMenuItem>
                  ))}
              </MaterialSelect>
            </StyledFormControl>
          </Row>
          <button
            className="button-page"
            onClick={handleSubmit}
            style={{
              width: '50%',
              marginTop: '1em',
            }}
            disabled={isLoading}
          >
            {!isLoading ? 'REGISTRAR' : 'Aguarde...'}
          </button>
        </ModalWrapper>
      </ModalContainer>
    </Wrapper>
  );
};

const SupplierBridesRow = ({
  cardData,
  type,
  segmentData,
  regionsData,
  updateOportunities,
  MarkClick,
  UnmarkClick,
  //contract = true,
  listIndex,
}) => {
  const {
    clientLogged,
    eventDate,
    clientPhone,
    clientName,
    clientEmail,
    clientCellphone,
    invitedNumber,
    cerimonyLocal,
    receptionLocal,
    nomeEvento,
    investment,
    indicationType,
    indicationBy,
    id,
    //eventId
  } = cardData;
  const [modalOpened, setModalOpen] = useState(false);
  const [contacted, setContacted] = useState(false);
  const [clientFirstName, setClientFirstName] = useState('');
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    if (clientName && clientName.length > 0) {
      setClientFirstName(clientName.split(' ')[0]);
    }
  }, [clientName, setClientFirstName]);

  const handleMarkClick = () => {
    MarkClick();
    setContacted(true);
    toast.success(`${clientFirstName} lembrado em Contatados`);
  };

  const handleUnmarkClick = () => {
    UnmarkClick();
    setContacted(false);
    toast.success(`${clientFirstName} desmarcado de Contatados`);
  };

  const handleCloseModal = modal => {
    if (modal === 1) {
      setModalOpen(false);
    }
    if (modal === 2) {
      setEditModal(false);
    }
    //updateOportunities();
    document.documentElement.classList.remove('html_modal-open');
  };

  if (type === 2 && contacted) {
    //Se clicar no checkbox na aba contatados, está desmarcando
    return <Row></Row>;
  } else {
    return (
      <>
        <TableRow
          style={{
            backgroundColor: listIndex % 2 === 0 ? 'white' : theme.colors.grey2,
            height: 'auto',
            width: 'auto',
            color: theme.colors.primary,
            fontFamily: theme.familyCategoryBody,
          }}
        >
          <TableCell>{id}</TableCell>
          <TableCell>{nomeEvento}</TableCell>
          <TableCell>
            {eventDate === dateUndefined
              ? 'indef.'
              : moment(eventDate).format('DD/MM/YYYY')}
          </TableCell>
          <TableCell
            style={{
              fontWeight: clientLogged ? 'bold' : '',
              color: 'black',
            }}
          >
            {clientName}
            {type === 1 && clientLogged !== 0 && (
              <EditSpan onClick={() => setEditModal(true)}> (editar)</EditSpan>
            )}
          </TableCell>
          <TableCell>{clientEmail}</TableCell>
          <TableCell>
            {clientCellphone ? clientCellphone : clientPhone ? clientPhone : ''}
          </TableCell>
          <TableCell>{cerimonyLocal}</TableCell>
          <TableCell>{receptionLocal || 'indef.'}</TableCell>
          <TableCell>{invitedNumber ? `${invitedNumber}` : 'indef.'}</TableCell>
          <TableCell>R${investment}</TableCell>
          {type !== 1 && (
            <TableCell>
              <StyledContractIcon onClick={() => setModalOpen(true)}>
                <ContractIcon /> Contrato Fechado
              </StyledContractIcon>
            </TableCell>
          )}
          <TableCell>
            {type === 0 && (
              <Checkbox
                checked={contacted}
                onClick={!contacted ? handleMarkClick : handleUnmarkClick}
              />
            )}
            {type === 2 && (
              <Checkbox checked={!contacted} onClick={handleUnmarkClick} />
            )}
          </TableCell>
          {type !== 1 && <TableCell>{indicationType}</TableCell>}
          {type !== 1 && <TableCell>{indicationBy}</TableCell>}
        </TableRow>
        {modalOpened && (
          <GenerateContractModal
            onCloseModal={() => handleCloseModal(1)}
            opened={modalOpened}
            segmentData={segmentData}
            regionsData={regionsData}
            clientEmail={clientEmail}
            updateOportunities={updateOportunities}
            brideId={cardData.clientId}
          />
        )}
        {editModal && (
          <EditBrideModal
            onCloseModal={() => handleCloseModal(2)}
            opened={editModal}
            brideData={cardData}
            segmentData={segmentData}
            regionsData={regionsData}
          />
        )}
      </>
    );
  }
};

export default SupplierBridesRow;
