import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { theme } from '../styles/theme';
import ModalContainer, { ModalTitle } from './ModalContainer';

const dummyActivityData = [
  { activity: 'Assistir vídeos e treinamentos', points: '5', maxPoints: '20' },
  {
    activity: 'Presença nas Rodadas de Networking',
    points: '10',
    maxPoints: '20',
  },
  { activity: 'Assiduidade do pagamento', points: '3', maxPoints: '30' },
  {
    activity: 'Quantidade de eventos que convidou e que consumiram',
    points: '2',
    maxPoints: '100',
  },
  {
    activity: 'Nota NPS da avaliação do atendimento',
    points: 'Nota NPS',
    maxPoints: '100 (varia ao longo do ano)',
  },
  {
    activity: 'Nota NPS da avaliação da pós execução',
    points: 'Nota NPS',
    maxPoints: '100 (varia ao longo do ano)',
  },
  {
    activity: 'Geração de negócios, a cada R$5mil gerado',
    points: '1',
    maxPoints: '150',
  },
  {
    activity: 'Tempo médio de retorno de 48hs sobre os orçamentos solicitados',
    points: '1',
    maxPoints: '50',
  },
  {
    activity: 'Eventos responderem a avaliação',
    points: '1',
    maxPoints: '50',
  },
];

const dummyLoyaltyData = [
  {
    period: '2015 e 2016',
    points: '80',
    maxPoints: '80',
  },
  {
    period: '2017 e 2018',
    points: '50',
    maxPoints: '80',
  },
  {
    period: '2019',
    points: '25',
    maxPoints: '80',
  },
];

const ActivityTable = styled.table`
  border-collapse: collapse;
  margin-top: 1.5rem;
  th:first-of-type {
    text-align: left;
  }
  tr {
    border-bottom: 1px solid ${theme.colors.grey3};
  }
  td {
    padding: 0.5rem 1rem 0.5rem 0;
    text-align: center;
    :first-of-type {
      text-align: left;
      padding-right: 0;
    }
    :last-of-type {
      padding-right: 0;
    }
  }
`;

const LoyaltyRow = styled.tr`
  border-bottom: none !important;
  td {
    padding-top: 1rem;
  }
  ~ tr {
    border: none;
    td:first-of-type {
      padding-left: 1rem;
    }
  }
`;

const HowToEarnModal = ({ closeModal, opened }) => {
  return (
    <ModalContainer opened={opened} closeModal={closeModal} hasArabesco={true}>
      <ModalTitle>Confira como ganhar moedas</ModalTitle>
      <ActivityTable>
        <thead>
          <tr>
            <th>Atividade</th>
            <th>Pontos por interação</th>
            <th>Máximo de pontos por atividade</th>
          </tr>
        </thead>
        <tbody>
          {dummyActivityData.map(d => (
            <tr key={d.activity}>
              <td>{d.activity}</td>
              <td>{d.points}</td>
              <td>{d.maxPoints}</td>
            </tr>
          ))}
          <LoyaltyRow>
            <td>Fidelização</td>
          </LoyaltyRow>
          {dummyLoyaltyData.map(d => (
            <tr key={d.period}>
              <td>{d.period}</td>
              <td>{d.points}</td>
              <td>{d.maxPoints}</td>
            </tr>
          ))}
        </tbody>
      </ActivityTable>
    </ModalContainer>
  );
};

HowToEarnModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

HowToEarnModal.defaultProps = {
  opened: false,
  closeModal: PropTypes.func.isRequired,
};

export default HowToEarnModal;
