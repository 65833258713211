import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes, { object } from 'prop-types';

import { medias } from '../utils/mediaQueries';
import { theme } from '../styles/theme';

const StyledContainer = styled.div`
  @media ${medias.laptopMin} {
    max-width: 30%;
    flex-direction: column;
    justify-content: space-around;
  }
  margin-top: 20px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitleContainer = styled.div`
  @media ${medias.laptopMin} {
    width: 100%;
  }

  width: 40%;

  p {
    @media ${medias.laptopMin} {
      margin: 1em 0;
    }

    @media ${medias.phoneMax} {
      font-size: 1em;
    }

    font-size: ${theme.fontSizeLg * 0.9}px;
    color: ${theme.colors.grey3};
    margin: 0;
  }
`;

const ImageContainer = styled.div`
  @media ${medias.laptopMin} {
    width: 100%;
    height: 65%;
  }

  @media ${medias.phoneMax} {
    width: 45%;
    height: 30%;
  }

  width: 289px;
  height: 163px;
`;

const Line = styled.div`
  @media ${medias.laptopMin} {
    width: 60px;
    height: 4px;
    margin: 0;
  }

  height: 2px;
  width: 60px;
  margin: 10px 0 10px 1px;
  background-color: ${theme.colors.primary};
`;

const Title = styled.h4`
  @media ${medias.laptopMin} {
    font-size: ${theme.fontSizeLg * 0.85}px;
    font-weight: bold;
    color: ${theme.colors.text_heading};
    margin: 1.33em 0;
  }

  @media ${medias.phoneMax} {
    font-size: 1.6em;
  }

  margin: 0;
  font-size: ${theme.fontSize * 2}px;
  text-align: left;
  color: ${theme.colors.secondary};
`;

const StyledImage = styled.img`
  max-width: 532;
  max-height: 301;
  border-radius: 30px;
`;

const BlogPost = ({ data: { title, description, link } }) => {
  const [imageLink] = useState('');

  // useEffect(() => {
  //   fetchBlogPosts();
  // }, [fetchBlogPosts]);

  // const fetchBlogPosts = useCallback(async () => {
  //   await fetch(
  //     `https://vestidasdebranco.com.br/wp-json/wp/v2/media/${imageCode}`
  //   )
  //     .then(response => response.json())
  //     .then(data =>
  //       setImageLink(
  //         data.media_details.sizes['newspaper-x-recent-post-big'].source_url
  //       )
  //     );
  // }, [imageCode]);

  return (
    <StyledContainer>
      <ImageContainer>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <StyledImage src={imageLink} href={link} />
        </a>
      </ImageContainer>
      <StyledTitleContainer>
        <Title>{title}</Title>
        <Line />
        <span dangerouslySetInnerHTML={{ __html: description }} />
      </StyledTitleContainer>
    </StyledContainer>
  );
};

BlogPost.propTypes = {
  posts: PropTypes.arrayOf(object),
};

export default BlogPost;
