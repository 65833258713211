import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import { toast } from 'react-toastify';

import { useStateValue } from '../../state';
import { Button, MaterialInput, Loader } from '../../components';
import { theme } from '../../styles/theme';
import { LockIcon, SignupIcon } from '../../components/icons';
import { PasswordModal } from '../../containers/';
import {
  bride as brideEndpoints,
  supplier as supplierEndpoints,
} from '../../services/api';
import { REGEX_EMAIL } from '../../utils/regex';

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
  padding: 0.5em;
  .input90 {
    width: 90%;
  }
  .btn_submit {
    border-color: ${theme.colors.text_heading};
    margin: 40px 0 30px 0;
    width: 90%;
    font-size: ${theme.fontSizeSm}px;
    font-weight: normal;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const FooterText = styled.p`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeSm}px;
  font-weight: 300;
  text-decoration: underline;
  cursor: pointer;
`;

const FooterItem = styled.div`
  display: flex;
  align-items: center;
`;

const LoginForm = ({ supplier, bride }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [{ visibility }, dispatch] = useStateValue();
  const [{ email, password }, setFormState] = useState({
    email: '',
    password: '',
  });

  const loginCallback = data => {
    const {
      userId,
      userName,
      token,
      firstLogin,
      rule,
      supplierCategory,
      supplierContract,
      supplierLogo,
      supplierRegion,
    } = data;

    localStorage.setItem('userId', userId);
    localStorage.setItem('userName', userName);
    localStorage.setItem('token', token);
    localStorage.setItem('isSupplier', supplier);
    localStorage.setItem('isBride', bride);
    localStorage.setItem('rule', rule);
    if (supplier) {
      localStorage.setItem('supplierCategory', supplierCategory);
      localStorage.setItem('supplierLogo', supplierLogo);
      localStorage.setItem('supplierRegion', supplierRegion);
      localStorage.setItem('supplierContract', supplierContract);
    }

    if (firstLogin === 1) {
      localStorage.setItem('firstLogin', firstLogin);
    }

    dispatch({
      type: 'registerSession',
      userId,
      userName,
      token,
      isSupplier: supplier,
      isBride: bride,
      rule,
      supplierCategory,
      supplierRegion,
      supplierLogo,
      supplierContract,
    });

    dispatch({
      type: 'toggleLoginModal',
      newVisibility: !visibility.loginModal,
    });

    if (bride && location.pathname === '/fornecedores') {
      return navigate('/app/noivos/fornecedores');
    }
    return navigate('/app');
  };

  const handleChange = (newText, field) => {
    setFormState({
      email,
      password,
      [field]: newText,
    });
  };

  const onClickJoin = () => {
    dispatch({
      type: 'toggleLoginModal',
      newVisibility: !visibility.loginModal,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!email || !password) {
      toast.error('Todos os campos são obrigatórios!');
      return false;
    }
    if (!REGEX_EMAIL.test(email)) {
      toast.error('Digite um e-mail válido!');
      return false;
    }

    handleLogin();
  };

  const handleLogin = async () => {
    setShowLoader(true);
    let loginResponse;

    if (bride) {
      loginResponse = await brideEndpoints.login({ email, password });
    } else if (supplier) {
      loginResponse = await supplierEndpoints.login({ email, password });
    }

    if (loginResponse && loginResponse.ok && loginResponse.data) {
      setShowLoader(false);
      loginCallback(loginResponse.data);
    } else if (
      loginResponse &&
      loginResponse.data &&
      loginResponse.data.message === 'Password Invalid.'
    ) {
      setShowLoader(false);
      toast.error('Senha incorreta.');
    } else if (
      loginResponse &&
      loginResponse.data &&
      loginResponse.data.message === 'Outstanding overdue payments.'
    ) {
      toast.error('Fornecedor possui parcelas vencidas em aberto');
    } else {
      setShowLoader(false);
      toast.error('Login não existente ou desativado.');
    }
  };

  return (
    <Wrapper onSubmit={handleSubmit}>
      <MaterialInput
        value={email}
        label="E-mail"
        onChange={event => handleChange(event.target.value, 'email')}
        className="input90"
      />
      <MaterialInput
        value={password}
        label="Senha"
        type="password"
        onChange={event => handleChange(event.target.value, 'password')}
        className="input90"
      />
      {!showLoader && (
        <Button className="btn_submit" text="ENTRAR" type="submit" />
      )}
      {showLoader && <Loader />}
      <FooterWrapper>
        <FooterItem>
          <LockIcon />
          <FooterText
            onClick={() =>
              dispatch({
                type: 'togglePasswordModal',
                newVisibility: true,
              })
            }
          >
            Esqueci minha senha
          </FooterText>
        </FooterItem>
        <FooterItem>
          <SignupIcon />
          <StyledLink to="/facaparte">
            <FooterText onClick={onClickJoin}>Faça parte</FooterText>
          </StyledLink>
        </FooterItem>
      </FooterWrapper>
      <PasswordModal />
    </Wrapper>
  );
};

export default LoginForm;
