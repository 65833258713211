import React from 'react';
import styled from 'styled-components';
import { Loader, MaterialInputLabel } from './';
import { theme } from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { toast } from 'react-toastify';
import { supplierContent } from '../services/api';

const FileInput = styled.div`
  --border-color: rgba(0, 0, 0, 0.42);
  width: 80%;
  position: relative;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  label {
    padding: 0 0.5rem 0 0;
    flex: 1;
    div {
      font-size: 0.8em;
      margin-top: 0.5em;
      color: ${theme.colors.grey3};
    }
  }
  button {
    border-left: 1px solid var(--border-color);
    padding: 0.5rem;
  }
  svg {
    width: 1em;
  }
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
`;

const ItemTitle = styled.h3`
  @media ${medias.phoneMax} {
    font-size: 0.8em;
  }

  font-size: ${theme.fontSize * 1.06}px;
  font-weight: bold;
  color: ${theme.colors.text_heading};
  margin-top: 0.6em;
`;

const SupplierInsertPhoto = ({ update, count }) => {
  const [imageName, setImageName] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  const imageUpload = e => {
    setImageName(undefined);
    const { files } = e.currentTarget;
    if (!files || files.length <= 0) {
      return;
    }
    const sendUploadReq = async data => {
      setLoading(true);
      const payload = {
        input: files[0].name,
        type: 1,
        data,
        count: Number(count) + 1,
      };
      const res = await supplierContent.updateMedia(payload);
      if (res.status === 412) {
        toast.error(res.data.message);
      }
      if (res.ok && res.data) {
        toast.success('Imagem salva com sucesso');
        update();
      } else {
        setLoading(false);
        setImageName(undefined);
        return toast.error('Erro ao salvar imagem.');
      }
      setLoading(false);
    };
    setImageName(files[0].name);
    getBase64(files[0]).then(data => sendUploadReq(data));
    setImageName(undefined);
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <ItemTitle>Inserir Fotos</ItemTitle>
      <FileInput>
        <MaterialInputLabel htmlFor="imageUpload">
          Clique para adicionar uma foto.
          {imageName && <div>{imageName}</div>}
        </MaterialInputLabel>
        <button>
          {/* SVG de upload */}
          <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
          >
            <path d="M11.492 10.172l-2.5 3.064-.737-.677 3.737-4.559 3.753 4.585-.753.665-2.5-3.076v7.826h-1v-7.828zm7.008 9.828h-13c-2.481 0-4.5-2.018-4.5-4.5 0-2.178 1.555-4.038 3.698-4.424l.779-.14.043-.789c.185-3.448 3.031-6.147 6.48-6.147 3.449 0 6.295 2.699 6.478 6.147l.044.789.78.14c2.142.386 3.698 2.246 3.698 4.424 0 2.482-2.019 4.5-4.5 4.5m.978-9.908c-.212-3.951-3.472-7.092-7.478-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.522-5.408" />
          </svg>
        </button>
        <input
          type="file"
          name="imageUpload"
          id="imageUpload"
          onChange={imageUpload}
          accept="image/*"
        />
      </FileInput>
    </>
  );
};
export default SupplierInsertPhoto;
