import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  MaterialSelect,
  MaterialInputLabel,
  MaterialMenuItem,
  MaterialDatePicker,
  MaterialInput,
} from '../components';
import { StyledButton } from '../components/styled';
import { ConfirmModal } from '../components';
import FormControl from '@material-ui/core/FormControl';
import { toast } from 'react-toastify';

import Arabesco from '../images/arabescos.svg';
import { medias } from '../utils/mediaQueries';
import { useStateValue } from '../state';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import { bride, siteContent } from '../services/api';
import theme from '../styles/theme';
import { hexToRgb } from '../utils/hexToRgb';
import { REGEX_EMAIL } from '../utils/regex';
import phoneMask from '../utils/phoneMask';
import { navigate } from '@reach/router';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: 0 0 50px 0;
  width: 90%;
`;

const AssetContainer = styled.div`
  @media ${medias.tabletMin} {
    background-size: contain;
  }

  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  ${({ bottom }) =>
    bottom
      ? 'margin-top: 1em; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;'
      : 'margin-bottom: 1em; border-top-left-radius: 10px; border-top-right-radius: 10px;'}
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  background-color: ${theme.colors.white};
  border-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  width: 100%;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const StyledDatePicker = styled(props => <MaterialDatePicker {...props} />)`
  width: 45%;
`;

const StyledFieldset = styled.div`
  width: 100%;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin-top: 0.25em;
`;

const StyledSpan = styled.p`
  margin: 0;
  width: 30%;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
`;

const Checkbox = styled.input`
  margin-right: 0.5em;
`;

const StyledFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
`;

const BrideReferForm = () => {
  const [
    {
      forms: { referForm },
      generalData: { states },
      events: { eventRegions },
    },
    dispatch,
  ] = useStateValue();
  const { name, email, phone, date, region, uf, city } = referForm;
  const [dateUndefined, setDateUndefined] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [availableCities, setAvailableCities] = useState(null);

  const isClickAvaliable =
    name && email && phone && date && region !== 0 && uf !== 0 && city !== 0;

  const handleChange = (newText, field) => {
    dispatch({
      type: 'changeReferForm',
      newText,
      field,
    });
  };

  useFetch({
    url: urls.siteContent.states,
    method: method.GET,
    condition: () => !states,
    callback: data =>
      dispatch({
        type: 'setStates',
        newStates: data,
      }),
  });

  const fetchCities = async codUf => {
    const response = await siteContent.cities(codUf);
    response.ok && response.data
      ? setAvailableCities(response.data)
      : toast.error('Não foi possivel recuperar as cidades!');
  };

  const clearForm = () => {
    handleChange('', 'name');
    handleChange('', 'email');
    handleChange('', 'phone');
    handleChange(new Date(), 'date');
    handleChange('', 'region');
    handleChange('', 'city');
    handleChange('', 'uf');
  };

  const personNotIndicated = response => {
    response.status === 412
      ? toast.error('Usuário já existe')
      : response.status === 409
      ? toast.error(response.data.message)
      : toast.error('Não foi possivel adicionar usuário');
  };

  const indicatePerson = async () => {
    const { phone } = referForm;

    if (!phone || phone.length < 14) {
      toast.error('Preencha o telefone corretamente.');
      return false;
    }
    const { date, region, uf, ...payload } = referForm; //eslint-disable-line
    const newDate = date.toISOString('YYYY-MM-DD').split('T');
    const body = {
      ...payload,
      weddingDate: dateUndefined ? null : newDate[0],
      codRegion: region,
    };
    const response = await bride.indicatePerson(body);
    response.ok && response.data
      ? toast.success('Usuário adicionado com sucesso!', {
          onClose: () => navigate('inicio'),
        })
      : personNotIndicated(response);
    clearForm();
  };

  const onClickRefer = () => {
    if (!REGEX_EMAIL.test(email)) {
      toast.error('Digite um e-mail válido!');
      return false;
    }
    setModalVisible(true);
  };

  return (
    <Wrapper>
      <ConfirmModal
        opened={modalVisible}
        closeModal={() => setModalVisible(false)}
        onClickConfirm={indicatePerson}
        text={'Você tem certeza que essa é a pessoa que você quer indicar?'}
      />
      <Card>
        <AssetContainer />
        <InputContainer>
          <MaterialInput
            value={name}
            style={{ width: '100%' }}
            label="Nome"
            onChange={event => handleChange(event.target.value, 'name')}
          />
          <MaterialInput
            value={email}
            style={{ width: '100%' }}
            label="E-mail"
            onChange={event => handleChange(event.target.value, 'email')}
          />
          <RowContainer>
            <StyledFormControl style={{ width: '45%' }}>
              <MaterialInputLabel htmlFor="uf">Estado</MaterialInputLabel>
              <MaterialSelect
                onChange={event => {
                  fetchCities(event.target.value);
                  handleChange(event.target.value, 'uf');
                }}
                value={uf}
                inputProps={{
                  name: 'uf',
                  id: 'uf',
                }}
              >
                <MaterialMenuItem value={0}>Selecione</MaterialMenuItem>
                {states
                  ? states.map(({ name, cod }) => (
                      <MaterialMenuItem key={cod} value={cod}>
                        {name}
                      </MaterialMenuItem>
                    ))
                  : null}
              </MaterialSelect>
            </StyledFormControl>
            <StyledFormControl style={{ width: '50%' }}>
              <MaterialInputLabel htmlFor="city">Cidade</MaterialInputLabel>
              <MaterialSelect
                onChange={event => handleChange(event.target.value, 'city')}
                value={city}
                inputProps={{
                  name: 'city',
                  id: 'city',
                }}
              >
                <MaterialMenuItem value={0}>Selecione</MaterialMenuItem>
                {availableCities
                  ? availableCities.map(({ nomeCidade, codCidadeIBGE }) => (
                      <MaterialMenuItem
                        key={codCidadeIBGE}
                        value={codCidadeIBGE}
                      >
                        {nomeCidade}
                      </MaterialMenuItem>
                    ))
                  : null}
              </MaterialSelect>
            </StyledFormControl>
          </RowContainer>
          <RowContainer>
            <MaterialInput
              style={{ width: '45%' }}
              value={phone}
              label="Telefone"
              onChange={event =>
                handleChange(phoneMask(event.target.value), 'phone')
              }
            />
            <StyledFields>
              <StyledDatePicker
                label="Data do evento"
                format={'DD/MM/YYYY'}
                value={date}
                onChange={date => handleChange(date, 'date')}
                style={{ width: '100%' }}
                disabled={dateUndefined}
              />
              <StyledFieldset>
                <Checkbox
                  type="checkbox"
                  name="hasDate"
                  value="Não definida"
                  onChange={() => setDateUndefined(!dateUndefined)}
                  checked={dateUndefined}
                />
                <StyledSpan>Não definida</StyledSpan>
              </StyledFieldset>
            </StyledFields>
          </RowContainer>
          <RowContainer>
            <StyledFormControl>
              <MaterialInputLabel htmlFor="region">
                Região do Evento
              </MaterialInputLabel>
              <MaterialSelect
                onChange={event => handleChange(event.target.value, 'region')}
                value={region}
                inputProps={{
                  name: 'region',
                  id: 'region',
                }}
              >
                <MaterialMenuItem value={0}>Selecione</MaterialMenuItem>
                {eventRegions &&
                  eventRegions.map(({ codRegiao: { codRegiao, Regiao } }) => {
                    return (
                      <MaterialMenuItem value={codRegiao} key={codRegiao}>
                        {Regiao}
                      </MaterialMenuItem>
                    );
                  })}
              </MaterialSelect>
            </StyledFormControl>
          </RowContainer>
          <StyledButton
            text="INDICAR"
            click={onClickRefer}
            disabled={!isClickAvaliable}
          />
        </InputContainer>
        <AssetContainer bottom />
      </Card>
    </Wrapper>
  );
};

BrideReferForm.propTypes = {
  format: PropTypes.string,
};

export default BrideReferForm;
