import { navigate } from 'gatsby';
import { userData } from './api';
export const isBrowser = () => typeof window !== 'undefined';

const getOpts = () => ({
  localUserId: isBrowser() && localStorage.getItem('userId'),
  localToken: isBrowser() && localStorage.getItem('token'),
  localUserRule: isBrowser() && localStorage.getItem('rule'),
  localUserCategory: isBrowser() && localStorage.getItem('supplierCategory'),
  localIsSupplier: isBrowser() && localStorage.getItem('isSupplier') === 'true',
  localIsBride: isBrowser() && localStorage.getItem('isBride') === 'true',
  localUserName: isBrowser() && localStorage.getItem('userName'),
  localFirstLogin: isBrowser() && localStorage.getItem('firstLogin'),
  localSiteSelectedRegion:
    isBrowser() && sessionStorage.getItem('siteSelectedRegion'),
  localSupplierLogo: isBrowser() && localStorage.getItem('supplierLogo'),
  localSupplierRegion: isBrowser() && localStorage.getItem('supplierRegion'),
  localSupplierCategory:
    isBrowser() && localStorage.getItem('supplierCategory'),
  localSupplierContract:
    isBrowser() && localStorage.getItem('supplierContract'),
});

export default (dispatch, inApp) => {
  const {
    localUserId,
    localToken,
    localIsSupplier,
    localIsBride,
    localUserName,
    localUserRule,
    localSupplierLogo,
    localSupplierRegion,
    localSupplierCategory,
    localSupplierContract,
  } = getOpts();

  if (
    localUserId &&
    localToken &&
    (localIsBride || localIsSupplier) &&
    localUserName
  ) {
    dispatch({
      type: 'registerSession',
      userId: localUserId,
      token: localToken,
      isSupplier: localIsSupplier,
      isBride: localIsBride,
      userName: localUserName,
      rule: localUserRule,
      supplierLogo: localSupplierLogo,
      supplierCategory: localSupplierCategory,
      supplierRegion: localSupplierRegion,
      supplierContract: localSupplierContract,
    });
    registerAccess(localUserId, localIsBride, localIsSupplier);
    if (!inApp) {
      console.log('Redirect to /app');
      navigate('/app');
    }
  }
};

export const checkSiteSelectedRegion = dispatch => {
  const { localSiteSelectedRegion } = getOpts();

  if (localSiteSelectedRegion) {
    dispatch({
      type: 'updateSiteSelectedRegion',
      siteSelectedRegion: localSiteSelectedRegion,
    });
  }
};

export const checkSupplierSelectedRegion = dispatch => {
  const {
    localSupplierCategory,
    localSupplierRegion,
    localSupplierContract,
  } = getOpts();

  if (localSupplierCategory && localSupplierRegion && localSupplierContract) {
    dispatch({
      type: 'updateSupplierRegion',
      supplierCategory: localSupplierCategory,
      supplierRegion: localSupplierRegion,
      supplierContract: localSupplierContract,
    });
  }
};

export const getAppRedirect = () => {
  const { localIsBride, localFirstLogin, localIsSupplier } = getOpts();

  if (localIsBride) {
    console.log(
      `Redirecting to /app/noivos${localFirstLogin === 1 ? '' : '/inicio'}`
    );
    return `/app/noivos${localFirstLogin === 1 ? '' : '/inicio'}`;
  } else if (localIsSupplier) {
    console.log('Redirecting to /app/fornecedor');
    return '/app/fornecedor';
  } else {
    console.log('Redirecting to /');
    return '/';
  }
};

export const registerAccess = async (
  localUserId,
  localIsBride,
  localIsSupplier
) => {
  if (localIsBride) {
    await userData.registerAccess({
      localUserId,
      type: 'bride',
    });
  } else if (localIsSupplier) {
    await userData.registerAccess({
      localUserId,
      type: 'supplier',
    });
  }
};
