import React, { Component } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import { theme } from '../../styles/theme';
import RightArrow from 'react-icons/lib/fa/angle-right';
import LeftArrow from 'react-icons/lib/fa/angle-left';
import { SearchBar } from '../../components';
import { absoluteCenter } from '../../utils/styleSnippets';
import { medias } from '../../utils/mediaQueries';
import { hexToRgb } from '../../utils/hexToRgb';
import { tracking } from '../../services/api';
import { Button } from '@material-ui/core';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media ${medias.largeMin} {
    position: relative;
    display: block;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  @media ${medias.phoneMax} {
    font-size: 1.6em;
  }
  font-size: 2em;
  font-family: 'Nexa Bold';
  font-weight: 900;
  color: ${theme.colors.white};
  margin: 0;
  text-align: center;
`;

const Subtitle = styled.p`
  @media ${medias.phoneMax} {
    font-size: 1.9em;
  }
  font-weight: 900;
  color: ${theme.colors.white};
  margin: 0;
  text-align: center;
`;

const SubtitlePart1 = styled.span`
  @media ${medias.phoneMax} {
    font-size: 0.8em;
  }
  font-size: 2em;
  font-family: 'Nexa Bold';
`;

const SubtitlePart2 = styled.span`
  @media ${medias.phoneMax} {
    font-size: 1.2em;
  }
  font-size: 3em;
  font-family: 'Anthum Thin';
`;

const StyledBackgroundSection = styled(BackgroundImage)`
  padding: 9rem 1rem;
  @media ${medias.largeMin} {
    padding: 39vh 1.5rem 30vh;
  }
  ::after {
    background-position: center 50%;
    background-size: cover;
  }

  :before {
    @media ${medias.phoneMax} {
      background-position-x: center;
    }
  }
`;

const Text = styled.div`
  @media ${medias.largeMax} {
    display: none;
  }
  font-family: 'Nexa Bold';
  font-size: ${theme.fontSizeXlg2}px;
  color: ${theme.colors.yellow};
  font-weight: bold;
  text-align: center;
  text-shadow: 0 2px 2px ${hexToRgb(theme.colors.secondary, 0.3)};
  position: absolute;
  top: 68%;
`;

const StyledSearchBar = styled(props => <SearchBar {...props} />)`
  @media ${medias.largeMin} {
    ${absoluteCenter}
    top: 83%;
    width: 60% !important;
    background-color: rgba(4, 7, 7, 0.7);
  }
`;

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <LeftArrow
      className={className}
      style={{
        ...style,
        display: 'block',
        color: theme.colors.grey2,
        position: 'absolute',
        left: '10%',
        zIndex: 1,
        top: '52.5%',
      }}
      onClick={onClick}
    />
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <RightArrow
      className={className}
      style={{
        ...style,
        display: 'block',
        color: theme.colors.grey2,
        position: 'absolute',
        right: '10%',
        zIndex: 1,
        top: '52.5%',
      }}
      onClick={onClick}
    />
  );
}

class SliderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { currentSlide: null, showSearch: true };
  }

  render() {
    const { content } = this.props;
    const slides = content.map(
      ({ title, subtitle, styledSubtitle, backgroundImg, text, link }) => (
        <StyledBackgroundSection
          key={backgroundImg}
          Tag="section"
          fluid={backgroundImg}
        >
          <Content>
            <Title>{title}</Title>
            <Subtitle>
              <SubtitlePart1>{subtitle}</SubtitlePart1>
              <SubtitlePart2>{styledSubtitle}</SubtitlePart2>
            </Subtitle>
            <Text>{text}</Text>
          </Content>
          {link && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '70px',
                marginRight: '160px',
              }}
            >
              <Button
                style={{ fontSize: '40px' }}
                onClick={() => {
                  if (link) {
                    window.open(link, '_blank');
                  }
                }}
              >
                Inscreva-se {'>'}{' '}
              </Button>
            </div>
          )}
        </StyledBackgroundSection>
      )
    );

    const handleSearch = async (input, dropdown, isSegment, segmentName) => {
      await tracking.search({
        userId: null,
        eventId: null,
        input: isSegment ? `segmento-${input}` : input,
        module: 'fornecedor',
      });
      navigate('/fornecedores', {
        state: { input, dropdown, isSegment, segmentName },
      });
    };

    const settings = {
      autoplay: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      dotsClass: 'slick-dots',
      fade: true,
      className: 'custom-slider',
      afterChange: current => {
        this.setState({
          currentSlide: current,
          showSearch:
            slides[current]?.props?.children?._self?.props?.content[current]
              ?.showSearch,
        });
      },
    };

    return (
      <Wrapper>
        <Slider ref={c => (this.slider = c)} {...settings}>
          {slides}
        </Slider>

        <StyledSearchBar
          onClickSearch={handleSearch}
          placeholder="O que você procura?"
          isHomePage
          segmentsDropdown
        />
      </Wrapper>
    );
  }
}

SliderComponent.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      backgroundImg: PropTypes.object.isRequired,
    })
  ).isRequired,
};

export default SliderComponent;
