import React, { useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import CloseButton from 'react-icons/lib/io/ios-close-outline';

import { theme } from '../../styles/theme';
import { useStateValue } from '../../state';
import {
  LoginChainIcon,
  RingsIcon,
  FormIcon,
  CirclesIcon,
} from '../../components/icons';
import LoginModalSection from './LoginModalSection';
// import { ExitButton } from '../../components/styled';
import { medias } from '../../utils/mediaQueries';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  min-width: 30%;
  min-height: 20%;
  background-color: ${theme.colors.grey1};
  border: 1px solid ${theme.colors.grey2};
  z-index: 3;
  border-radius: 5px;

  display: flex;
  flex: 1;
  flex-direction: column;
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledIcon = styled(LoginChainIcon)`
  height: 50px;
  width: 50px;
  @media ${medias.laptopMin} {
    height: 85px;
    width: 85px;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1em;
`;

const Title = styled.div`
  font-size: ${theme.fontSizeLg}px;
  color: ${theme.colors.grey3};
  text-align: left;
  font-weight: bold;
  @media ${medias.laptopMin} {
    font-size: ${theme.fontSizeXlg}px;
  }
`;

const SubTitle = styled.div`
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.text_heading};
  font-weight: bold;
`;

const TitleAndSub = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 0.5em;
  @media ${medias.laptopMin} {
    margin-left: 1.5em;
  }
`;

const SectionsContainer = styled.div`
  margin-top: 5%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ExitButton = styled(CloseButton)`
  height: 21px;
  width: 21px;
  color: ${theme.colors.error};
  cursor: pointer;
  position: relative;
  @media ${medias.laptopMin} {
    left: 93%;
    top: 7.5%;
  }
  left: 85%;
  top: 5%;
`;

const LoginModal = () => {
  const node = useRef();
  const [{ visibility }, dispatch] = useStateValue();

  const handleClick = useCallback(
    event => {
      if (!node.current) {
        return;
      }
      if (node.current.contains(event.target)) {
        return;
      }
      dispatch({
        type: 'toggleLoginModal',
        newVisibility: false,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);

  return (
    <>
      {visibility.loginModal && (
        <Wrapper ref={node}>
          <ExitButton
            onClick={() =>
              dispatch({
                type: 'toggleLoginModal',
                newVisibility: !visibility.loginModal,
              })
            }
          >
            x
          </ExitButton>
          <ModalHeader>
            <IconsWrapper>
              <StyledIcon />
            </IconsWrapper>
            <TitleAndSub>
              <Title>Login</Title>
              <SubTitle>Acesso Restrito</SubTitle>
            </TitleAndSub>
          </ModalHeader>
          <SectionsContainer>
            <LoginModalSection
              open={visibility.fianceeAccess}
              action={() =>
                dispatch({
                  type: 'toggleFianceeAccess',
                  newVisibility: !visibility.fianceeAccess,
                })
              }
              icon={<RingsIcon />}
              text="Acesso para Eventos"
              bride
            />
            <LoginModalSection
              action={() =>
                dispatch({
                  type: 'toggleSupplierAccess',
                  newVisibility: !visibility.supplierAccess,
                })
              }
              open={visibility.supplierAccess}
              icon={<FormIcon />}
              text="Acesso do Fornecedor"
              supplier
            />
            <LoginModalSection
              action={() =>
                dispatch({
                  type: 'toggleFranchiseAccess',
                  newVisibility: !visibility.franchiseAccess,
                })
              }
              open={visibility.franchiseAccess}
              icon={<CirclesIcon />}
              text="Acesso do Franqueado"
              franchise
            />
          </SectionsContainer>
        </Wrapper>
      )}
    </>
  );
};

LoginModal.propTypes = {};

export default LoginModal;
