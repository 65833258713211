import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { MaterialDatePicker, MaterialInput } from '../components';
import { StyledButton } from '../components/styled';
import { toast } from 'react-toastify';
import moment from 'moment';

import { useStateValue } from '../state';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { hexToRgb } from '../utils/hexToRgb';
import { REGEX_EMAIL } from '../utils/regex';
import Arabesco from '../images/arabescos.svg';
import { joinUs } from '../services/api';
import undefinedDate from '../utils/undefinedDate';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: 0 0 50px 0;
  width: 100%;
`;

const AssetContainer = styled.div`
  @media ${medias.tabletMin} {
    background-size: contain;
  }

  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  ${({ bottom }) =>
    bottom
      ? 'margin-top: 1em; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;'
      : 'margin-bottom: 1em;'}
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  background-color: ${theme.colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 90%;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  width: 100%;
`;

const DateContainer = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
  width: 100%;
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${theme.colors.grey2};
`;

const Title = styled.h4`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeLg * 0.85}px;
  margin: 0 0 1em 0;
`;

const StyledHalfInput = styled(InputMask)`
  width: 45%;
`;

const StyledDatePicker = styled(props => <MaterialDatePicker {...props} />)`
  width: 45%;
`;

const StyledFieldset = styled.div`
  width: 100%;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin-top: 0.25em;
`;

const Checkbox = styled.input`
  margin-right: 0.5em;
`;

const StyledSpan = styled.p`
  margin: 0;
  width: 30%;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
`;

const BrideForm = props => {
  const [
    {
      forms: { bridesForm },
    },
    dispatch,
  ] = useStateValue();
  const [dateUndefined, setDateUndefined] = useState(false);

  const { name, email, phone, cellphone, date, cerimonyLocation } = bridesForm;

  const handleChange = (newText, field) => {
    dispatch({
      type: 'changeBrideForm',
      newText,
      field,
    });
  };

  const { format } = props;

  const submitFormData = async () => {
    const payload = phone
      ? {
          name,
          email,
          phone,
          cellphone,
          weddingDate: dateUndefined
            ? undefinedDate
            : moment(date).format('YYYY-MM-DD'),
          cerimonyLocation,
        }
      : {
          name,
          email,
          cellphone,
          weddingDate: dateUndefined
            ? undefinedDate
            : moment(date).format('YYYY-MM-DD'),
          cerimonyLocation,
        };

    const response = await joinUs.bride(payload);

    if (response.ok) {
      toast.success(
        'Obrigado por se inscrever em nossa lista de espera! Em breve retornaremos através do email informado.',
        {
          onClose: () => window.location.reload(),
          autoClose: 8000,
        }
      );
      return;
    }

    if (response.status === 400) {
      toast.error(
        'Houve um erro na submissão! Favor verificar se os campos foram preenchidos corretamente.'
      );
      return;
    }

    if (response.status === 409) {
      toast.error(response.data.message);
      return;
    }

    toast.error('Houve um erro na submissão do cadastro.');
  };

  return (
    <Wrapper>
      <Card>
        <AssetContainer />
        <TitleContainer>
          <Title>Lista de espera</Title>
        </TitleContainer>
        <Line />
        <InputContainer>
          <MaterialInput
            value={name}
            style={{ width: '100%' }}
            label="Seu nome"
            required
            onChange={event => handleChange(event.target.value, 'name')}
          />
          <MaterialInput
            value={email}
            style={{ width: '100%' }}
            label="Seu melhor e-mail"
            required
            onChange={event => handleChange(event.target.value, 'email')}
          />
          <RowContainer>
            <StyledHalfInput
              value={phone}
              label="Telefone"
              mask="(99) 9999-9999"
              onChange={event => handleChange(event.target.value, 'phone')}
            >
              {inputProps => <MaterialInput {...inputProps} />}
            </StyledHalfInput>
            <StyledHalfInput
              value={cellphone}
              label="Celular"
              required
              mask="(99) 99999-9999"
              onChange={event => handleChange(event.target.value, 'cellphone')}
            >
              {inputProps => <MaterialInput {...inputProps} />}
            </StyledHalfInput>
          </RowContainer>
          <MaterialInput
            value={cerimonyLocation}
            style={{ width: '100%' }}
            label="Espaço da recepção"
            required
            onChange={event =>
              handleChange(event.target.value, 'cerimonyLocation')
            }
          />
          {/* </RowContainer> */}
          <DateContainer>
            <StyledDatePicker
              label="Data do evento"
              required
              format={format || 'DD/MM/YYYY'}
              value={date}
              onChange={date => handleChange(date, 'date')}
              disabled={dateUndefined}
              disablePast
            />
            <StyledFieldset>
              <Checkbox
                type="checkbox"
                name="hasDate"
                value="Não definida"
                onChange={() => setDateUndefined(!dateUndefined)}
                checked={dateUndefined}
              />
              <StyledSpan>Não definida</StyledSpan>
            </StyledFieldset>
          </DateContainer>
          <StyledButton
            text="SALVAR"
            click={() => {
              if (
                !name ||
                !email ||
                !cellphone ||
                (!date && !dateUndefined) ||
                !cerimonyLocation
              ) {
                toast.error('Preencha todos os campos obrigatórios!');
                return false;
              }

              if (!REGEX_EMAIL.test(email)) {
                toast.error('Digite um e-mail válido!');
                return false;
              }

              submitFormData();
              return true;
            }}
          />
        </InputContainer>
        <AssetContainer bottom />
      </Card>
    </Wrapper>
  );
};

BrideForm.propTypes = {
  format: PropTypes.string,
};

export default BrideForm;
