import React from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { SeparadorIcon } from './icons';
import { hexToRgb } from '../utils/hexToRgb';

const Wrapper = styled.div`
  @media ${medias.phoneMax} {
    align-items: left;
  }
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 0 20px 0;
  align-items: center;
`;

const Title = styled.h2`
  @media ${medias.phoneMax} {
    font-size: 1.2em;
  }

  color: ${theme.colors.grey3};
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 0 0;

  background-color: ${theme.colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const VideoContainer = styled.iframe`
  @media ${medias.phoneMax} {
    width: 100%;
    height: 338px;
  }

  margin: 0 0 20px 0;
  width: 599px;
  height: 338px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const Text = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.8em;
  }

  width: 45%;
  font-size: ${theme.fontSize * 1.06}px;
  color: ${theme.colors.grey3};
  text-align: left;
  margin: 20px 0 40px 0;
`;

//@TODO Desktop version layout missing!
const VideoCard = () => {
  return (
    <Wrapper>
      <Title>O que fazemos</Title>
      <Card>
        <VideoContainer
          src="https://www.youtube.com/embed/YmNmH7UwPDo"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullscreen
        />
        <SeparadorIcon />
        <TextContainer>
          <Text>
            Em 2014, nossa Co-Fundadora Maíse Góis se tornou noiva, e entrou em
            um mundo novo, onde percebeu várias dificuldades que a noiva
            enfrenta neste momento. Fundada em 2015, a Rede Celebrar (antiga
            Vestidas de Branco) é a primeira Rede de Vantagens e Clube de
            Fidelidade para eventos no mundo.
          </Text>
          <Text>
            Através da parceria com empresas de renome e com vasta experiência
            no mercado, a Rede Celebrar conseguiu formatar um clube de vantagens
            exclusivo para eventos. Nele, os eventos tem acesso a premiações e
            atendimento exclusivo, além de descontos reais significativos que
            podem ultrapassar R$ 20.000,00.
          </Text>
        </TextContainer>
      </Card>
    </Wrapper>
  );
};

export default VideoCard;
