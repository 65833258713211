import React from 'react';
import styled from 'styled-components';

import { theme } from '../styles/theme';
import ModalContainer from './ModalContainer';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledTable = styled.table`
  border: 2px solid ${theme.colors.grey1};
  border-collapse: separate;
  border-spacing: 10px 10px;
  width: 100%;

  thead {
    font-size: 0.9em;
  }

  tbody {
    font-size: 0.8em;
  }
`;

const CategoryModal = ({ closeModal, opened }) => {
  return (
    <ModalContainer opened={opened} closeModal={closeModal} hasArabesco>
      <Wrapper>
        <h3>Informações sobre categorias dos eventos:</h3>
        <p>
          As categorias dos eventos existem para permitir uma maior variedade de
          prêmios para os eventos que consomem mais. Incentivamos,
          primeiramente, o consumo em uma diversidade maior de fornecedores
          dentro da rede, mas também o valor total investido.
        </p>
        <p>
          Todos eventos iniciam no sistema na categoria Light. Para subir de
          categoria, os eventos deverão realizar o upgrade de acordo com um dos
          critérios abaixo especificados:
        </p>
        <p>
          IMPORTANTE: O contrato assinado válido para a entrada na Rede não é
          contabilizado nos contratos fechados, uma vez que os eventos ainda não
          faziam parte da Rede e passam a fazer a partir do 1º contrato
          informado na Plataforma.
        </p>
        <h5>Critérios para upgrade</h5>
        <StyledTable>
          <thead>
            <tr>
              <td>Upgrade</td>
              <td>Fornecedores *</td>
              <td>Contratos</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>LIGHT &gt; PLUS</td>
              <td>3 novos fornecedores distintos</td>
            </tr>
            <tr>
              <td>PLUS &gt; VIP </td>
              <td>6 novos fornecedores distintos</td>
            </tr>
          </tbody>
        </StyledTable>
        <h5>Observações importantes:</h5>
        <span>
          - O fornecedor que inseriu o Evento na Rede não é contabilizado
          <br />- Os pontos são computados separadamente por região
        </span>
      </Wrapper>
    </ModalContainer>
  );
};

export default CategoryModal;
