import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
//import useFetch, { method } from '../hooks/useFetch';
//import { urls } from '../services/urls';
import QRCode from 'qrcode.react';
import { PageWrapper, PageTitle } from './pageComponents';
import { supplierContent } from '../services/api';

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: space-evenly;
  ${({ border }) => border && `border-bottom: 2px solid ${theme.colors.grey1};`}
  .btn {
    text-decoration: none;
  }
  .btn:hover {
    background: ${theme.colors.yellow};
    color: ${theme.colors.secondary};
    font-weight: bold;
    text-decoration: underline;
  }
`;

const HistoryText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.7em;
  }

  font-size: ${theme.fontSizeMed}px;
  color: ${theme.colors.grey3};
  margin: 0 0 10px 5px;
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;
  width: 48%;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  background-color: ${theme.colors.text_heading};
  color: ${theme.colors.white};
  border-color: ${theme.colors.text_heading};
`;

const SupplierPointsOutgoing = () => {
  const [myId, setMyId] = useState(null);
  const [downloadURL, setDownloadURL] = useState(null);

  useEffect(() => {
    const fetchOwnId = async () => {
      const res = await supplierContent.getOwnId();

      if (res.ok && res.data) {
        setMyId(res.data.codFornecedor);
      }
    };
    fetchOwnId();
  }, []);

  useEffect(() => {
    if (myId) {
      const canvas = document.querySelector('.Qrcode > canvas');
      const pngUrl = canvas.toDataURL('image/png');
      setDownloadURL(pngUrl);
    }
  }, [myId]);

  return (
    <PageWrapper>
      <PageTitle>Meu QR Code</PageTitle>
      <HistoryText>
        Aqui você pode gerar um QRCode e disponibilizá-lo para que eventos se
        cadastrem na Rede Celebrar com a sua indicação
      </HistoryText>
      <div style={{ padding: '1em 0' }} className="Qrcode">
        <QRCode
          id="123456"
          value={`https://redecelebrar.com.br/indicacao/noivo/${myId}`}
          size={85}
          level={'H'}
          includeMargin={true}
        />
      </div>
      <RowContainer>
        {' '}
        {/* <StyledHalfButton
            className="btn"
            text={ */}
        <StyledLink href={downloadURL} download="myQRCode.png">
          {!myId
            ? 'AGUARDE... GERANDO'
            : downloadURL
            ? 'FAZER DOWNLOAD'
            : 'AGUARDE... GERANDO'}
        </StyledLink>
        {/* }
            disabled={!myId}
          /> */}
      </RowContainer>
    </PageWrapper>
  );
};

export default SupplierPointsOutgoing;
