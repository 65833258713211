import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  DropdownRegions,
  DropdownSegments,
  DropdownFavorites,
  OrderBy,
  SegmentsContainer,
} from './';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { SearchIcon, FilterIcon } from './icons';
import Grid from '@material-ui/core/Grid';
import { useStateValue } from '../state';

const StyledGrid = styled(Grid)`
  height: auto;
  background-color: ${theme.colors.secondary};
  margin: initial;
  width: 100%;
`;

const StyledRegionContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-content: center;
  justify-content: ${({ filter }) => (filter ? 'flex-start' : 'flex-end')};
  padding-top: 2px;
`;

const StyledLine = styled.div`
  height: 2px;
  background-color: ${theme.colors.white};
`;

const StyledVerticalLine = styled.div`
  width: 1px;
  height: 30px;
  background-color: ${theme.colors.white};
  margin: 5px 0 3px 0;
`;

const FilterText = styled.p`
  @media ${medias.phoneMin} {
    margin: 0 68% 0 0;
  }

  @media ${medias.smallerTabletMin} {
    margin: 0;
  }

  font-family: Lato;
  font-size: ${theme.fontSize}px;
  font-weight: bold;
  line-height: 2.38;
  letter-spacing: -0.4px;
  text-align: left;
  margin: 0 50% 0 0;
  color: ${theme.colors.white};
  text-decoration: underline;
  text-decoration-color: ${theme.colors.primary};

  &:hover {
    cursor: pointer;
  }
`;

const StyledInput = styled.input`
  background-color: transparent;
  border: none;
  font-family: Lato;
  font-size: ${theme.fontSize}px;
  font-weight: bold;
  line-height: 2.38;
  letter-spacing: -0.4px;
  text-align: left;
  color: ${theme.colors.white};
  padding-left: 5px;

  @media ${medias.smallerTabletMax} {
    width: ${props => (props.isPublicSite ? '87%' : '100%')};
  }

  @media ${medias.phoneMax} {
    width: ${props => (props.isPublicSite ? '82%' : '100%')};
  }
  width: ${props => (props.isPublicSite ? '91%' : '100%')};
`;

const StyledIcon = styled(SearchIcon)`
  margin: 10px 15px 0 10px;

  width: 20px;
  height: 20px;

  &:hover {
    cursor: pointer;
  }
`;

const StyledFilterIcon = styled(FilterIcon)`
  margin-top: 10px;

  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;

const SearchBar = ({
  className,
  filter,
  advantages,
  onClickSearch,
  onClickFilter,
  placeholder,
  searchFromHome = '',
  droppedFromHome = '',
  handleChangeOrder,
  favorites,
  isHomePage = false,
  segmentsDropdown = false,
  prizesPage = false,
  selectWhoFav = false,
  home = false,
}) => {
  const [searchString, setSearchString] = useState(searchFromHome);
  const [searchSegment, setSearchSegment] = useState(null);
  const [searchValue, setSearchValue] = useState(droppedFromHome);
  const [inputIsSegment, setInputIsSegment] = useState(false);
  const [showSegmentsContainer, setShowSegmentsContainer] = useState(false);
  const [filteredSegments, setFilteredSegments] = useState([]);
  const [isTyping, setIsTyping] = useState(false);

  const [
    {
      segments: { segments },
      session: { userId },
    },
  ] = useStateValue();

  const handleKeyPress = key => {
    if (key === 'Enter') {
      return filter
        ? onClickSearch(searchString)
        : inputIsSegment
        ? onClickSearch(
            searchSegment,
            searchValue,
            inputIsSegment,
            encodeCharacter(searchString)
          )
        : onClickSearch(searchString, searchValue);
    }
  };

  useEffect(() => {
    if (segments) {
      const orderedSegments = segments.sort((a, b) => a.Segmento - b.Segmento);
      setFilteredSegments(orderedSegments);
    }
  }, [segments]);

  const handleInputChange = value => {
    setSearchString(value);

    if (segments) {
      const _filteredSegments = segments.filter(
        item => item.Segmento.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setFilteredSegments(_filteredSegments);
      if (filteredSegments.length) {
        setShowSegmentsContainer(true);
      } else {
        setShowSegmentsContainer(false);
      }
    }
  };

  const handleIsTyping = value => {
    if (value) {
      setIsTyping(true);
    } else {
      setIsTyping(false);
    }
  };

  const handleSegmentClick = segment => {
    setSearchString(segment.Segmento);
    setSearchSegment(segment.codSegmento);
    setInputIsSegment(true);
  };

  const encodeCharacter = string => {
    if (string) {
      return string.replace('&', '%26');
    }
    return string;
  };

  return (
    <Wrapper>
      <StyledGrid container spacing={3} filter={filter} className={className}>
        {handleChangeOrder && (
          <Grid item xs={12} sm={2} md={2}>
            <OrderBy onChangeOrder={handleChangeOrder} />
          </Grid>
        )}
        <Grid
          item
          xs={home ? 9 : 12}
          sm={home ? 9 : handleChangeOrder ? 4 : userId ? 6 : 12}
          md={
            home ? 9 : handleChangeOrder ? 4 : prizesPage ? 8 : userId ? 6 : 12
          }
        >
          <StyledInput
            value={searchString}
            type="text"
            placeholder={placeholder}
            onChange={event => event && handleInputChange(event.target.value)}
            onFocus={() => setShowSegmentsContainer(true)}
            onBlur={() => setShowSegmentsContainer(false)}
            onKeyUp={event => handleIsTyping(event.target.value)}
            onKeyPress={e => handleKeyPress(e.key)}
            isPublicSite={userId ? false : true}
          />

          {!userId && (
            <StyledIcon
              onClick={() =>
                filter
                  ? onClickSearch(encodeCharacter(searchString))
                  : inputIsSegment
                  ? onClickSearch(
                      searchSegment,
                      searchValue,
                      inputIsSegment,
                      encodeCharacter(searchString)
                    )
                  : onClickSearch(encodeCharacter(searchString), searchValue)
              }
            />
          )}
          <StyledLine />
        </Grid>
        {home && (
          <Grid item xs={3} sm={3} md={3}>
            <StyledIcon
              onClick={() =>
                filter
                  ? onClickSearch(encodeCharacter(searchString))
                  : inputIsSegment
                  ? onClickSearch(
                      searchSegment,
                      searchValue,
                      inputIsSegment,
                      encodeCharacter(searchString)
                    )
                  : onClickSearch(encodeCharacter(searchString), searchValue)
              }
            />
          </Grid>
        )}

        {userId && !home && (
          <Grid
            item
            xs={handleChangeOrder || prizesPage ? 12 : 6}
            sm={prizesPage ? 12 : 6}
            md={prizesPage ? 3 : 6}
          >
            <StyledRegionContainer filter={filter}>
              <StyledVerticalLine />
              <StyledIcon
                onClick={() =>
                  filter
                    ? onClickSearch(encodeCharacter(searchString))
                    : inputIsSegment
                    ? onClickSearch(
                        searchSegment,
                        searchValue,
                        inputIsSegment,
                        encodeCharacter(searchString)
                      )
                    : onClickSearch(encodeCharacter(searchString), searchValue)
                }
              />
              {filter && (
                <>
                  <StyledFilterIcon onClick={onClickFilter} />
                  <FilterText onClick={onClickFilter}>Mais Filtros</FilterText>
                </>
              )}
              {advantages && (
                <DropdownSegments
                  value={searchValue.Segmento}
                  onSelectItem={setSearchValue}
                  placeholder={'Segmento'}
                />
              )}
              {favorites && (
                <DropdownFavorites
                  value={searchValue.value}
                  onSelectItem={setSearchValue}
                  placeholder={
                    selectWhoFav ? 'Selecione quem favoritou' : 'Filtrar'
                  }
                />
              )}
              {!filter && !advantages && !favorites && (
                <DropdownRegions
                  value={searchValue}
                  onSelectItem={setSearchValue}
                  placeholder={'Região'}
                />
              )}
            </StyledRegionContainer>
            {!prizesPage && <StyledLine />}
          </Grid>
        )}
      </StyledGrid>
      {segmentsDropdown &&
        filteredSegments.length > 0 &&
        showSegmentsContainer && (
          <SegmentsContainer
            segments={filteredSegments}
            onClick={segment => handleSegmentClick(segment)}
            isHomePage={isHomePage}
            isTyping={isTyping}
          />
        )}
    </Wrapper>
  );
};

SearchBar.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.bool,
};

export default SearchBar;
