import React, { useState } from 'react';

import styled from 'styled-components';
import { medias } from '../utils/mediaQueries';
import theme from '../styles/theme';
import { MaterialInput } from '../components';
import { /*MaterialInputLabel,*/ Button } from './';
import InputMask from 'react-input-mask';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import { supplier } from '../services/api';
import { toast } from 'react-toastify';
import moment from 'moment';
import phoneMask from '../utils/phoneMask';

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  width: 90%;
`;

/*const FileInput = styled.div`
  --border-color: rgba(0, 0, 0, 0.42);
  width: 90%;
  position: relative;
  margin-top: 10px;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  label {
    padding: 0 0.5rem 0 0;
    flex: 1;
    div {
      font-size: 0.8em;
      margin-top: 0.5em;
      color: ${theme.colors.grey3};
    }
  }
  button {
    border-left: 1px solid var(--border-color);
    padding: 0.5rem;
  }
  svg {
    width: 1em;
  }
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
`;*/

const StyledHalfInput = styled(InputMask)`
  width: 30%;
`;

const HalfButton = styled(props => <Button {...props} />)`
  @media ${medias.phoneMax} {
    height: 40px;
    font-size: ${theme.fontSizeSm}px;
  }
  background-color: ${props =>
    props.selected ? theme.colors.white : theme.colors.text_heading};
  color: ${props =>
    props.selected ? theme.colors.text_heading : theme.colors.white};
  border-color: ${theme.colors.text_heading};
  width: 90%;
  font-size: ${theme.fontSizeSm}px;
  text-align: center;
  margin-top: 1em;
`;

const SupplierProfileDataCard = () => {
  const [form, setForm] = useState({
    name: null,
    brideEmail: null,
    website: null,
    instagram: null,
    bridePhone: null,
    brideCellphone: null,
    comercialEmail: null,
    comercialPhone: null,
    resume: null,
    supplierOrigin: null,
    file: null,
    dateOrigin: null,
  });
  //const [imageName, setImageName] = useState(undefined);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useFetch({
    url: urls.supplierContent.supplierData,
    method: method.GET,
    condition: () => !data,
    callback: response => {
      if (response) {
        setData(response);
        const {
          name,
          brideEmail,
          bridePhone,
          brideCellphone,
          instagram,
          resume,
          supplierOrigin,
          website,
          dateOrigin,
          comercialEmail,
          comercialPhone,
        } = response;
        setForm({
          ...form,
          name: name,
          brideEmail: brideEmail,
          website: website,
          instagram: instagram,
          bridePhone: bridePhone,
          brideCellphone: brideCellphone,
          resume: resume,
          supplierOrigin: supplierOrigin,
          dateOrigin: moment(dateOrigin).format('DD/MM/YYYY h:mm:ss'),
          comercialEmail: comercialEmail,
          comercialPhone: comercialPhone,
        });
      }
    },
  });

  const updateField = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const updateProfile = async () => {
    setLoading(true);
    // eslint-disable-next-line
    const { supplierOrigin, dateOrigin, ...payload } = form;
    const response = await supplier.changeData(payload);
    response.ok && response.data
      ? toast.success('Dados alterados com sucesso.')
      : toast.error('Erro ao alterar dados.');
    setLoading(false);
  };

  /*const getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  const imageUpload = e => {
    setImageName(undefined);
    const { files } = e.currentTarget;
    if (!files || files.length <= 0) {
      return;
    }
    setImageName(files[0].name);
    getBase64(files[0]).then(data =>
      setForm({
        ...form,
        file: data,
      })
    );
  };*/

  const handlePhoneChange = value => {
    setForm({
      ...form,
      comercialPhone: phoneMask(value),
    });
  };

  return (
    <>
      <MaterialInput
        style={{ width: '90%' }}
        label="Nome"
        name="name"
        value={form.name}
        onChange={updateField}
      />
      <MaterialInput
        style={{ width: '90%' }}
        label="E-mail evento"
        name="brideEmail"
        value={form.brideEmail}
        onChange={updateField}
      />
      <MaterialInput
        style={{ width: '90%' }}
        label="E-mail comercial"
        name="comercialEmail"
        value={form.comercialEmail}
        onChange={updateField}
      />
      <RowContainer>
        <MaterialInput
          style={{ width: '45%' }}
          label="Site"
          name="website"
          value={form.website}
          onChange={updateField}
        />
        <MaterialInput
          style={{ width: '45%' }}
          label="Instagram"
          name="instagram"
          value={form.instagram}
          onChange={updateField}
        />
      </RowContainer>
      <MaterialInput
        label="Resumo"
        style={{ width: '90%' }}
        multiline
        rows="3"
        name="resume"
        value={form.resume}
        onChange={updateField}
      />
      {/*
      <FileInput>
        <MaterialInputLabel htmlFor="imageUpload">
          {typeof imageName === 'string' ? 'Trocar' : 'Carregar'} logo
          {imageName && <div>{imageName}</div>}
        </MaterialInputLabel>
        <button aria-title="Botão para enviar uma imagem">
          * SVG de upload *
          <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
          >
            <path d="M11.492 10.172l-2.5 3.064-.737-.677 3.737-4.559 3.753 4.585-.753.665-2.5-3.076v7.826h-1v-7.828zm7.008 9.828h-13c-2.481 0-4.5-2.018-4.5-4.5 0-2.178 1.555-4.038 3.698-4.424l.779-.14.043-.789c.185-3.448 3.031-6.147 6.48-6.147 3.449 0 6.295 2.699 6.478 6.147l.044.789.78.14c2.142.386 3.698 2.246 3.698 4.424 0 2.482-2.019 4.5-4.5 4.5m.978-9.908c-.212-3.951-3.472-7.092-7.478-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.522-5.408" />
          </svg>
        </button>
        <input
          type="file"
          name="imageUpload"
          id="imageUpload"
          onChange={imageUpload}
          accept="image/*"
        />
      </FileInput>
      */}
      <RowContainer>
        <StyledHalfInput
          label="Telefone fixo evento"
          mask="(99) 9999-9999"
          name="bridePhone"
          value={form.bridePhone}
          onChange={updateField}
        >
          {inputProps => <MaterialInput {...inputProps} />}
        </StyledHalfInput>
        <StyledHalfInput
          label="Telefone celular evento"
          mask="(99) 99999-9999"
          name="brideCellphone"
          value={form.brideCellphone}
          onChange={updateField}
        >
          {inputProps => <MaterialInput {...inputProps} />}
        </StyledHalfInput>
        <MaterialInput
          style={{ width: '30%' }}
          label="Telefone comercial"
          name="comercialPhone"
          value={form.comercialPhone}
          onChange={e => handlePhoneChange(e.target.value)}
        />
      </RowContainer>
      <RowContainer>
        <MaterialInput
          // label="Data de Associação"
          style={{ width: '45%' }}
          value={form.dateOrigin}
          disabled
        />
        <MaterialInput
          // label="Tipo de Associação"
          style={{ width: '45%' }}
          value={form.supplierOrigin}
          disabled
        />
      </RowContainer>
      {!loading && (
        <HalfButton text="ADICIONAR / SALVAR" click={updateProfile} />
      )}
    </>
  );
};

export default SupplierProfileDataCard;
